import { makeStyles } from "@material-ui/core/styles";

export const propertyListingStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0px"
        }
    },

    search_container: {
        // display: "grid",
        // gridTemplateColumns: "80% 20%",
    },

    btn: {
        color: "#F4F5F7",
        border: "none",
        textTransform: "capitalize",
        fontSize: ".8rem",
        borderRadius: "5px",
        padding: "15px 30px",
        fontFamily: "Open Sans-Semibold, sans-serif",
        fontWeight: 600,
        background: "#4786FF",

        [theme.breakpoints.down("md")]: {
            padding: "15px 10px"
        },

        [theme.breakpoints.down("sm")]: {
            fontSize: "1.2rem",
            width: "100%",
            padding: "15px"
        },

        "& .btn_name": {
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        },

        "& .icon": {
            fontSize: "1.2rem",
            marginRight: "7px",
            color: "#F4F5F7",
            fontWeight: "bold",
            [theme.breakpoints.down("sm")]: {
                fontSize: "1.8rem",
                marginRight: "0px"
            }
        },

        "&:hover": {
            color: "#F4F5F7",
            background: "#4786FF"
        },

        "&:focus": {
            color: "#F4F5F7"
        }
    },

    left_section: {
        display: "flex",
        width: "550px",
        justifyContent: "space-between",

        [theme.breakpoints.down("sm")]: {
            width: "100%"
        },

        "& .search__result": {
            color: "#043B5C",
            fontFamily: "Open Sans-Semibold, sans-serif",
            // marginTop: "20px",
            fontSize: "13px",
            transform: "translateY(50%)",
            [theme.breakpoints.down("1112")]: {
                display: "none"
            }
        }
    },

    right_section: {
        // transform: "translateY(20px)",
    },

    section_container: {
        marginTop: "20px"
    },

    action__button: {
        display: "flex",

        [theme.breakpoints.down("sm")]: {
            display: "block"
        },

        "& .action__button__checkboxs": {
            border: "1px solid #043B5C",
            width: "120px",
            padding: "10px",
            /* margin: 0 auto; */
            textAlign: "center",
            borderRadius: "5px",

            "& label": {
                color: "#043B5C",
                fontFamily: "Open Sans, sans-serif",
                marginLeft: "12px",
                fontSize: "0.9rem",
                marginBottom: "0px",
                fontWeight: 600
            }
        },

        "& .action__button__dropdown": {
            // marginLeft: "20px"
        }
    },

    listing: {
        [theme.breakpoints.down("sm")]: {
            padding: "0px !important"
        }
    }
}));
