import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { showNotifications } from "#/store/actions/notification.actions";
const AddPropertyButton = ({ history, text }) => {
  const Styles = makeStyles((theme) => ({
    btn: {
      color: "#F4F5F7",
      border: "none",
      textTransform: "capitalize",
      fontSize: ".8rem",
      borderRadius: "5px",
      padding: "15px 30px",
      fontFamily: "Open Sans-Semibold, sans-serif",
      fontWeight: 600,
      background: "#4786FF",
      minWidth: "auto",

      [theme.breakpoints.down("md")]: {
        padding: "15px 10px"
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
        width: "100%",
        padding: "15px"
      },

      "& .btn_name": {
        [theme.breakpoints.down("sm")]: {
          display: "none"
        }
      },

      "& .icon": {
        fontSize: "1.2rem",
        // marginRight: "7px",
        color: "#F4F5F7",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
          fontSize: "1.8rem",
          marginRight: "0px"
        }
      },

      "&:hover": {
        color: "#F4F5F7",
        background: "#4786FF"
      },

      "&:focus": {
        color: "#F4F5F7"
      }
    }
  }));

  const classes = Styles();

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const RouteToProperty = () => {
    const { is_email_verified } = user;
    if (!is_email_verified) {
      dispatch(showNotifications("Please, verify your email to continue", "error"));
      return;
    } else {
      history.push("/create");
    }
  };
  return (
    <Button to="/create" className={classes.btn} onClick={RouteToProperty}>
      <AddIcon className="icon" />
      <span className="btn_name">{text}</span>
    </Button>
  );
};

export default React.memo(withRouter(AddPropertyButton));
