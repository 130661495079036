import React from "react";
import RentalBillCard from "./RentalBillCard";
import { styled } from "@mui/material";
import CustomBtn from "#/components/buttons/CustomBtn";
import { APP_ROUTES } from "#/constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import useRentalPaymentCart from "../../hooks/useRentalPaymentCart";
import { useSelector } from "react-redux";

// Where payment details object is created and set in LOCALSTORAGE
const handlePayNow = (tenant, apartmentId, rentalCart, history) => {
  if (!tenant || !apartmentId || rentalCart?.length === 0) return;

  const paymentDetails = {
    tenant,
    apartmentId,
    rentalCart
  };
  localStorage.setItem("RENTAL_PAYMENTS", JSON.stringify(paymentDetails));
  history.push(APP_ROUTES.dashboard.rentalpayments);
};

// This makes use of the useRentalPaymentCart HOOKs
const RentalBillsList = ({ apartmentId, billsList }) => {
  const { tenantRecord } = useSelector((store) => store.tenant);

  const tenant = tenantRecord[0];

  const UNPAID_BILLS_WITH_PAID = billsList?.filter((bill) => {
    const result =
      bill.status === "unpaid" ||
      bill.status === "incomplete" ||
      (bill.status === "paid" && isUpdatedAtWithinLastDay(bill.updatedAt));
    return result;
  });

  function isUpdatedAtWithinLastDay(updatedAt) {
    const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000; // One day in milliseconds
    const now = new Date();
    const updatedAtDate = new Date(updatedAt);
    const timeDifference = now - updatedAtDate;

    return timeDifference < ONE_DAY_IN_MILLISECONDS;
  }

  // IF UNPAID BILLS ARRAY HAS A BILL WITH MONTHLY RENT, FILTER OUT THE HOUSE RENT BILL
  const filteredHouseRent = (bills) => {
    const hasMonthlyRent = bills?.some((bill) => bill.name === "Monthly Rent");

    if (hasMonthlyRent) {
      return bills?.filter((bill) => bill.name !== "House Rent");
    }
    return bills;
  };

  // CALLING THE FILTERED BILL FUNCTION
  const filtered_bills = filteredHouseRent(UNPAID_BILLS_WITH_PAID);

  const history = useHistory();
  const { rentalPaymentCart, handleSelectItem } = useRentalPaymentCart(billsList);
  return (
    <>
      {billsList?.length > 0 ? (
        <>
          <Wrapper>
            {filtered_bills.map((bill) => {
              return (
                <RentalBillCard
                  key={bill._id}
                  bill={bill}
                  handleSelectBill={handleSelectItem}
                />
              );
            })}
          </Wrapper>
          <CustomBtn
            style={{ margin: "0 auto", padding: "0.45rem 2.5rem" }}
            onClick={() => handlePayNow(tenant, apartmentId, rentalPaymentCart, history)}>
            Generate Invoice
          </CustomBtn>
        </>
      ) : (
        <em>No Next Payments.</em>
      )}
    </>
  );
};

export default RentalBillsList;

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.45rem;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    justify-content: start;
    gap: 1rem;
  }
`;

// const LOAN_RENT_BILLS = billsList.filter((bills) => {
//   return bills.is_loan_rent === true;
// });

// const OTHER_BILLS = billsList.filter((bills) => {
//   return bills.is_loan_rent === false;
// });

// const UNPAID_BILLS = billsList.filter((bills) => {
//   return bills.status === "unpaid" || "incomplete";
// });
// let isHouseRentPaid = false;

// for (const bill of billsList) {
//   if (bill.name === "House Rent" && bill.status === "paid") {
//     isHouseRentPaid = true;
//     break; // Stop iterating
//   }
// }

// console.log("External ishouserentbill...", isHouseRentPaid);

// const UNPAID_BILLS_WITH_PAID = billsList.filter((bill) => {
//   console.log("Bill name:", bill.name);
//   console.log("Bill status:", bill.status);
//   console.log("Bill updated time:", bill.updatedAt);
//   console.log("Final isHouseRentPaid value:", isHouseRentPaid);
//   const result =
//     bill.status === "unpaid" ||
//     bill.status === "incomplete" ||
//     (bill.status === "paid" &&
//       isUpdatedAtWithinLastDay(bill.updatedAt) &&
//       isHouseRentPaid);

//   console.log("Result:", result); // Log the result

//   return result;
// });

// const UNPAID_BILLS_WITH_PAID = billsList.filter((bill) => {
//   console.log("Bill name...", bill.name);
//   console.log("Bill status...", bill.status);
//   console.log("Bill updated time...", bill.updatedAt);

//   // Check if the bill is "House Rent" and paid
//   const isHouseRentPaid = bill.name === "House Rent" && bill.status === "paid";
//   console.log("THis is isHourentPaid...", isHouseRentPaid);

//   return (
//     bill.status === "unpaid" ||
//     bill.status === "incomplete" ||
//     isHouseRentPaid ||
//     (bill.status === "paid" && isUpdatedAtWithinLastDay(bill.updatedAt))
//   );
// });
// const UNPAID_BILLS = billsList.filter((bills) => {
//   return bills.status === "unpaid" || bills.status === "incomplete";
// });

// const isHouseRentPaid = billsList.find((bill) => {
//   // Check if the bill is "House Rent" and paid
//   const isHouseRentPaid = bill.name === "House Rent" && bill.status === "paid";
//   console.log("isHouseRentPaid:", isHouseRentPaid);

//   // if (isHouseRentPaid) {
//   //   console.log("Found House Rent Paid. Stopping iteration.");
//   //   return true; // Stop iterating and return true
//   // }
//   // return true;

//   if (isHouseRentPaid) {
//     console.log("Found House Rent Paid. Stopping iteration.");
//     return true; // Stop iterating and return true
//   }
//   return false; // Continue iterating
// });

// const UNPAID_BILLS_WITH_PAID = billsList.filter((bill) => {
//   console.log("Bill name...", bill.name);
//   console.log("Bill status...", bill.status);
//   console.log("Bill updated time...", bill.updatedAt);

//   // Check if the bill is "House Rent" and paid
//   const isHouseRentPaid = bill.name === "House Rent" && bill.status === "paid";
//   console.log("THis is isHourentPaid...", isHouseRentPaid);

//   return (
//     bill.status === "unpaid" ||
//     bill.status === "incomplete" ||
//     isHouseRentPaid ||
//     (bill.status === "paid" && isUpdatedAtWithinLastDay(bill.updatedAt))
//   );
// });
