import { propertyServiceApi } from "#/apis/propertyServiceApi";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";

const useFetch = (url, options = {}) => {
  const { loadingMessage, errorMessage } = options;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (signal) => {
      if (!url) return;
      setLoading(true);
      setError(null);
      setData(null);

      try {
        const response = await propertyServiceApi().get(url, { signal });
        const result = response.data.content || response.content || response.data;
        setData(result);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setError(err.message || errorMessage || "An error occurred.");
        }
      } finally {
        setLoading(false);
      }
    },
    [url, errorMessage]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller.signal);

    return () => {
      controller.abort(); // Cancel the fetch operation when component unmounts
    };
  }, [url, fetchData]);

  const refetch = () => {
    fetchData();
  };

  return { data, loading, error, refetch, loadingMessage, errorMessage };
};

export default useFetch;
