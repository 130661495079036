import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useAddressDropdown from "#/hooks/propertyservice-hooks/useAddressDropdown";
import useAddMultipleImages from "#/hooks/propertyservice-hooks/useAddMultipleImages";
import {
  generatePropertyId,
  createProperty,
  updateProperty
} from "#/store/actions/property.actions";
import useArrowDropdown from "./useArrowDropdown";
import { showNotifications } from "#/store/actions/notification.actions";
import {
  OPTIONS_PROPERTY_TYPE,
  PROPERTY_ROLES
} from "#/components/propertyForm/propertyForm.constants";

const usePropertyForm = (isForEdit = false, setCurrentStep) => {
  //------------------------------------------------------------------
  //Store
  //------------------------------------------------------------------
  const liveizy_id = useSelector((state) => state.auth.user.liveizy_id);
  const role = useSelector((state) => state.auth.user.roles);

  const { propertyId, creatingProperty } = useSelector((state) => state.property); //Used when creating a property

  //------------------------------------------------------------------
  //Helper hooks
  //------------------------------------------------------------------
  const dispatch = useDispatch();
  const history = useHistory();

  //custom hooks
  const { addressDropdownHelpers } = useAddressDropdown();
  const { addMultipleImagesHelpers } = useAddMultipleImages();
  const propertyTypeHelpers = useArrowDropdown(OPTIONS_PROPERTY_TYPE);
  const createPropertyAs = useArrowDropdown(PROPERTY_ROLES);

  //------------------------------------------------------------------
  //States
  //------------------------------------------------------------------
  const [propertyCoverImage, setPropertyCoverImage] = useState();
  const [propertyCoverImagePreview, setPropertyCoverImagePreview] = useState();
  const [previouslyUploadedImages, setPreviouslyUploadedImages] = useState([]);
  const [propertyName, setPropertyName] = useState("");
  const [errors, setErrors] = useState({
    propertyCoverImage: false,
    propertyName: false,
    propertyAddress: false,
    propertyType: false,
    createPropertyAs: false
  });

  //------------------------------------------------------------------
  //Function declarations
  //------------------------------------------------------------------
  //Property Name update and validation
  const handleSetPropertyName = useCallback((e) => {
    setPropertyName(e.target.value);
  }, []);

  //Upload property cover Image
  const handleSetCoverImage = (e) => {
    const file = e.target.files[0];
    setPropertyCoverImage(file);

    //If user did not select any file, get out of function
    if (file === undefined) return;

    const reader = new FileReader();

    reader.onload = function () {
      setPropertyCoverImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleRemovePreviouslyUploadedImage = useCallback(
    (index) => {
      const filteredData = previouslyUploadedImages.filter((item, i) => {
        return index !== i;
      });

      setPreviouslyUploadedImages(filteredData);
    },
    [previouslyUploadedImages]
  );

  //Setting errors
  const handleSetErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: true
      };
    });
  }, []);

  //removing errors(NOTE: this was not merged into the handleSetErrors function because of some edge cases)
  const handleRemoveErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: false
      };
    });
  }, []);

  //Submission of form to create property
  const handleSubmitProperty = (e, isUpdating, sentPropertyId) => {
    e.preventDefault();

    const { address } = addressDropdownHelpers;
    const { houseNumber, line, state, lga, lcda, otherAddress } = address;

    const { allMultipleImages } = addMultipleImagesHelpers;

    let created_by = "";

    if (role.includes("Landlord") && role.includes("Agent")) {
      created_by = createPropertyAs.selectedOption.mainValue;
    } else if (role.includes("Landlord") && !role.includes("Agent")) {
      created_by = "landlord";
    } else if (!role.includes("Landlord") && role.includes("Agent")) {
      created_by = "agent";
    }

    //--------------------------------------------
    //Validations
    //--------------------------------------------
    if (
      !propertyCoverImage ||
      propertyName.length === 0 ||
      houseNumber.length === 0 ||
      line.length === 0 ||
      state.length === 0 ||
      lga.length === 0 ||
      propertyTypeHelpers.selectedOption.mainValue === "selectType"
    ) {
      dispatch(showNotifications("Please Fill all required fields", "error"));
    }

    if (!propertyCoverImage) {
      return handleSetErrors("propertyCoverImage");
    }

    if (propertyName.length === 0) {
      return handleSetErrors("propertyName");
    }

    if (
      houseNumber.length === 0 ||
      line.length === 0 ||
      state.length === 0 ||
      lga.length === 0
    ) {
      return handleSetErrors("propertyAddress");
    }

    if (propertyTypeHelpers.selectedOption.mainValue === "selectType") {
      return handleSetErrors("propertyType");
    }

    if (
      createPropertyAs.selectedOption.mainValue === "select" &&
      role.includes("Landlord") &&
      role.includes("agent")
    ) {
      return handleSetErrors("createPropertyAs");
    }
    //--------------------------------------------
    //--------------------------------------------
    //--------------------------------------------

    const formData = new FormData();

    formData.append("title", propertyName);
    formData.append("house_number", houseNumber);
    formData.append("street", line);
    formData.append("state", state);
    formData.append("lga", lga);
    formData.append("lcda", lcda || "");
    formData.append("property_type", propertyTypeHelpers.selectedOption.mainValue);
    formData.append("property_id", propertyId || sentPropertyId);
    formData.append("country", "Nigeria");
    formData.append("property_images", propertyCoverImage);
    formData.append("created_by", created_by);
    formData.append("other_address", otherAddress);
    formData.append("otherManagers", []);

    //console.log('previouslyUploadedImages', previouslyUploadedImages);
    if (previouslyUploadedImages?.length > 0) {
      for (let i = 0; i < previouslyUploadedImages.length; i++) {
        formData.append("image_urls", previouslyUploadedImages[i]);
      }
    }

    if (allMultipleImages?.length > 0) {
      for (let i = 0; i < allMultipleImages.length; i++) {
        formData.append("property_images", allMultipleImages[i]);
      }
    }

    if (created_by == "landlord") {
      formData.append("owner", liveizy_id);
    }

    if (created_by == "agent") {
      formData.append("property_manager", liveizy_id);
    }

    if (isUpdating) {
      dispatch(updateProperty(formData, sentPropertyId, () => {}));
    } else {
      dispatch(
        createProperty(formData, () => {
          setPropertyName("");
          addressDropdownHelpers.setAddress({
            houseNumber: "",
            line: "",
            state: "",
            lga: "",
            lcda: ""
          });
          propertyTypeHelpers.setSelectedOption(OPTIONS_PROPERTY_TYPE[0]);
          addMultipleImagesHelpers.setAllMultipleImages();
          addMultipleImagesHelpers.setAllMultipleImagesPreview([]);
          history.push("/create/apartment");
        })
      );
    }
  };

  //------------------------------------------------------------------
  //Use Effects
  //------------------------------------------------------------------
  //Remove error message when user fulfills validation conditions
  useEffect(() => {
    const { houseNumber, line, state, lga, lcda } = addressDropdownHelpers.address;

    if (
      houseNumber.length !== 0 &&
      line.length !== 0 &&
      state.length !== 0 &&
      lga.length !== 0
    ) {
      handleRemoveErrors("propertyAddress");
    }

    if (propertyCoverImage !== undefined) {
      handleRemoveErrors("propertyCoverImage");
    }

    if (propertyName.length !== 0) {
      handleRemoveErrors("propertyName");
    }

    if (propertyTypeHelpers.selectedOption.mainValue !== "selectType") {
      handleRemoveErrors("propertyType");
    }

    if (createPropertyAs.selectedOption.mainValue !== "select") {
      handleRemoveErrors("createPropertyAs");
    }
  }, [
    addressDropdownHelpers.address,
    propertyCoverImage,
    propertyName,
    propertyTypeHelpers.selectedOption.mainValue,
    createPropertyAs.selectedOption.mainValue
  ]);

  //Get a random property id from the backend
  useEffect(() => {
    if (!isForEdit) {
      dispatch(generatePropertyId());
    }
  }, [isForEdit]);

  return {
    propertyFormHelpers: {
      propertyId,
      handleSetCoverImage,
      propertyCoverImage,
      propertyCoverImagePreview,
      propertyName,
      handleSetPropertyName,
      addressDropdownHelpers,
      addMultipleImagesHelpers,
      propertyTypeHelpers,
      errors,
      handleSetErrors,
      handleSubmitProperty,
      creatingProperty,
      setPropertyCoverImage,
      setPropertyCoverImagePreview,
      setPropertyName,
      previouslyUploadedImages,
      setPreviouslyUploadedImages,
      handleRemovePreviouslyUploadedImage,
      createPropertyAs
    }
  };
};

export default usePropertyForm;
