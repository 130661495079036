import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import PropertyForm from "#/components/propertyForm/propertyForm";
import BackComponent from "#/components/propertycomponents/back-component";
import usePropertyForm from "#/hooks/propertyservice-hooks/usePropertyForm";
import { getPropertyById } from "#/store/actions/property.actions";

import {
  OPTIONS_PROPERTY_TYPE,
  PROPERTY_ROLES
} from "#/components/propertyForm/propertyForm.constants";
import { returnUserData } from "#/apis/propertyServiceApi";

const EditProperty = () => {
  const dispatch = useDispatch();
  const { liveizy_id } = returnUserData();
  const history = useHistory();
  const { propertyId: urlPropertyId } = useParams();

  const initialPropValues = useSelector((state) => state.property.propertyById); //Used when editing a property
  const { propertyFormHelpers } = usePropertyForm(true);

  const {
    propertyId,
    propertyCoverImage,
    propertyCoverImagePreview,
    handleSetCoverImage,
    propertyName,
    handleSetPropertyName,
    addressDropdownHelpers,
    addMultipleImagesHelpers,
    errors,
    handleSubmitProperty,
    creatingProperty,
    propertyTypeHelpers,
    setPropertyCoverImage,
    setPropertyCoverImagePreview,
    setPropertyName,
    previouslyUploadedImages,
    setPreviouslyUploadedImages,
    handleRemovePreviouslyUploadedImage,
    createPropertyAs
  } = propertyFormHelpers;

  //If Property already exists and thus user wants to edit
  useEffect(() => {
    if (initialPropValues) {
      const {
        property_images,
        title,
        house_number,
        street,
        state,
        lga,
        lcda,
        property_type,
        other_address
      } = initialPropValues;
      //set default property image
      setPropertyCoverImagePreview(property_images[0]);
      setPropertyCoverImage(property_images[0]);

      //set default property name
      setPropertyName(title);

      //set default property address
      addressDropdownHelpers.setAddress({
        houseNumber: house_number,
        line: street,
        state: state,
        lga: lga,
        lcda: lcda,
        otherAddress: other_address
      });

      //set default property type
      const selectedPropertyTypeObject = OPTIONS_PROPERTY_TYPE.filter((item) => {
        return item.mainValue === property_type;
      });
      propertyTypeHelpers.setSelectedOption(selectedPropertyTypeObject[0]);

      //set default other property images
      let otherImages = [];
      for (let i = 1; i < property_images.length; i++) {
        //Starting loop from index "1" because index "0" is for the cover image
        otherImages.push(property_images[i]);
      }
      setPreviouslyUploadedImages([...otherImages]);

      createPropertyAs.setSelectedOption(PROPERTY_ROLES[0]);
    }
  }, [initialPropValues]);

  useEffect(() => {
    dispatch(getPropertyById(urlPropertyId, "charges", "tenant"));
  }, [urlPropertyId]);

  if (
    initialPropValues &&
    initialPropValues.owner !== liveizy_id &&
    initialPropValues.property_manager !== liveizy_id &&
    !initialPropValues.otherManagers.includes(liveizy_id)
  ) {
    history.push("/property/list");
    return null;
  }

  return (
    <>
      <div style={{ marginBottom: "20px", display: "inline-block" }}>
        <BackComponent />
      </div>
      <PropertyForm
        propertyId={urlPropertyId}
        handleSetCoverImage={handleSetCoverImage}
        propertyCoverImagePreview={propertyCoverImagePreview}
        propertyCoverImage={propertyCoverImage}
        handleSetPropertyName={handleSetPropertyName}
        propertyName={propertyName}
        addressDropdownHelpers={addressDropdownHelpers}
        creatingProperty={creatingProperty}
        multipleImagesHelpers={addMultipleImagesHelpers}
        errors={errors}
        propertyTypeHelpers={propertyTypeHelpers}
        isForEdit={true}
        handleSubmitProperty={handleSubmitProperty}
        previouslyUploadedImages={previouslyUploadedImages}
        handleRemovePreviouslyUploadedImage={handleRemovePreviouslyUploadedImage}
        styles={{ borderRadius: "20px" }}
        createPropertyAs={createPropertyAs}
      />
    </>
  );
};

export default EditProperty;
