import axios from "axios";

import { useHistory } from "react-router-dom";
import { PROPERTY_ACTIONTYPES_CONSTANTS } from "../constants";
import { showNotifications } from "#/store/actions/notification.actions";
import { propertyServiceApi } from "#/apis/propertyServiceApi";

//------------------------------------------------------------------
//Variables and Helpers
//------------------------------------------------------------------
const {
  GET_PROP_ID_FAIL,
  GET_PROP_ID_PENDING,
  GET_PROP_ID_SUCCESS,
  CREATE_PROPERTY_FAIL,
  CREATE_PROPERTY_PENDING,
  CREATE_PROPERTY_SUCCESS,
  GET_ALL_PROPERTIES_FAIL,
  GET_ALL_PROPERTIES_SUCCESS,
  GET_ALL_PROPERTIES_PENDING,
  GET_PROPERTY_BY_ID_PENDING,
  GET_PROPERTY_BY_ID_SUCCESS,
  GET_PROPERTY_BY_ID_FAIL,
  UPDATE_PROPERTY_FAIL,
  UPDATE_PROPERTY_PENDING,
  UPDATE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_PENDING,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAIL,
  UPDATE_PROPERTY_INFO,
  CLEAR_PROPERTY_INFO,
  ADD_PM_PENDING,
  ADD_PM_SUCCESS,
  ADD_PM_FAIL,
  CREATE_PRO_OWNER_PENDING,
  CREATE_PRO_OWNER_SUCCESS,
  CREATE_PRO_OWNER_FAIL,
  CREATE_PRO_MANAGER_PENDING,
  CREATE_PRO_MANAGER_SUCCESS,
  CREATE_PRO_MANAGER_FAIL
} = PROPERTY_ACTIONTYPES_CONSTANTS;
const userDetailsInLocalStorage = JSON.parse(localStorage.getItem("data"));
const BASE_URL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

//------------------------------------------------------------------
//Get Property Id
//------------------------------------------------------------------
export const generatePropertyId = () => async (dispatch) => {
  //Undecided on what pending should really do, anyways lets have it
  dispatch({
    type: GET_PROP_ID_PENDING
  });

  try {
    const res = await axios.get(`${BASE_URL}/property/generateUniqueID`);

    if (res.status === 200) {
      dispatch({
        type: GET_PROP_ID_SUCCESS,
        payload: res.data.payload
      });
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while generating property Id: ${error}, ${error.response.data.payload}`,
          "error"
        )
      );
    }

    //Ideally this should never even get here, but anyways lets have it
    dispatch({
      type: GET_PROP_ID_FAIL,
      payload: error
    });
  }
};

//------------------------------------------------------------------
//Create Property
//------------------------------------------------------------------
export const createProperty = (payload, cb) => async (dispatch) => {
  dispatch({ type: CREATE_PROPERTY_PENDING });

  try {
    const res = await axios.post(`${BASE_URL}/property/store`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    if (res.status === 201) {
      dispatch({
        type: CREATE_PROPERTY_SUCCESS,
        payload: res.data.payload
      });

      dispatch(showNotifications("Property created successfully", "success"));
      cb();
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while creating property: ${error}, ${error.response.data.payload}`,
          "error"
        )
      );
    }
    dispatch({
      type: CREATE_PROPERTY_FAIL,
      payload: error
    });
  }
};

//---------------------------------------------------------------------
//GET ALL PROPERTIES
//--------------------------------------------------------------------
export const getAllProperties = () => async (dispatch, getState) => {
  const { liveizy_id } = getState().auth.user;
  dispatch({ type: GET_ALL_PROPERTIES_PENDING });

  try {
    const response = await axios.get(
      `${BASE_URL}/property/ownerProperties/${liveizy_id}`
    );
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_ALL_PROPERTIES_SUCCESS,
        payload: response.data.payload
      });
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      dispatch(
        showNotifications(
          `Error occurred while getting all properties: ${error}`,
          "error"
        )
      );
    }
    dispatch({ type: GET_ALL_PROPERTIES_FAIL });
  }
};

//---------------------------------------------------------------------
//GET PROPERTy BY ID
//--------------------------------------------------------------------
export const getPropertyById = (id, populate1, populate2, populate3) => async (
  dispatch
) => {
  dispatch({ type: GET_PROPERTY_BY_ID_PENDING });
  try {
    const response = await axios.get(`${BASE_URL}/property/show/${id}`, {
      params: {
        populate1,
        populate2,
        populate3
      }
    });
    if ([200, 201].includes(response.status)) {
      dispatch({
        type: GET_PROPERTY_BY_ID_SUCCESS,
        propertyById: response.data.payload
      });
    }
  } catch (error) {
    dispatch(showNotifications(`${error.response.data.message}`, "error"));
  }
};

//------------------------------------------------------------------
//Update Property
//------------------------------------------------------------------
export const updateProperty = (payload, id, cb) => async (dispatch) => {
  dispatch({ type: UPDATE_PROPERTY_PENDING });

  const history = useHistory();
  try {
    const res = await axios.put(`${BASE_URL}/property/update/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    if ([200, 201].includes(res.status)) {
      dispatch(showNotifications("Property updated successfully", "success"));
      setTimeout(() => {
        cb();
      }, 2000);
    }
  } catch (error) {
    dispatch(showNotifications(`${error.response.data.message}`, "error"));
    history.goBack();
  }
};

//-------------------------------------------------------------------
//Delete Property
//-------------------------------------------------------------------

export const deleteProperty = (id, cb) => async (dispatch) => {
  dispatch({ type: DELETE_PROPERTY_PENDING });
  propertyServiceApi()
    .delete(`/property/delete/${id}`)
    .then((response) => {
      dispatch(showNotifications("Property deleted successfully", "success"));
      dispatch({
        type: DELETE_PROPERTY_SUCCESS,
        payload: response.data.payload
      });
      dispatch(getAllProperties());
      cb();
    })
    .catch((error) => {
      dispatch({ type: DELETE_PROPERTY_FAIL, error });
      dispatch(showNotifications(error.response.data.message, "error"));
    });
};
//------------------------------------------------------------------
//Populate Property Info, used when we want to add a new apartment to a property through the property listing screen
//------------------------------------------------------------------
export const populateCurrentPropertyInfo = (payload, cb) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROPERTY_INFO,
      payload
    });
    cb();
  } catch (error) {
    dispatch({
      type: CLEAR_PROPERTY_INFO
    });
  }
};

export const addPropertyManager = (payload, LandlordLiveizyID, cb) => async (
  dispatch
) => {
  dispatch({ type: ADD_PM_PENDING });

  try {
    const res = await axios.patch(
      `${BASE_URL}/property-owner/${LandlordLiveizyID}`,
      payload
    );

    if ([200, 201].includes(res.status)) {
      dispatch({ type: ADD_PM_SUCCESS });
      dispatch(showNotifications("Property Manager added successfully", "success"));
      cb();
    }
  } catch (error) {
    dispatch({
      type: ADD_PM_FAIL
    });
  }
};

export const createPropertyOwner = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_PRO_OWNER_PENDING });

  try {
    const res = await axios.post(`${BASE_URL}/property-owner`, payload);

    if ([200, 201].includes(res.status)) {
      dispatch({ type: CREATE_PRO_OWNER_SUCCESS });
    }
  } catch (error) {
    dispatch({
      type: CREATE_PRO_OWNER_FAIL
    });
  }
};

export const createPropertyManager = (payload) => async (dispatch) => {
  dispatch({ type: CREATE_PRO_MANAGER_PENDING });

  try {
    const res = await axios.post(`${BASE_URL}/property-manager`, payload);

    if ([200, 201].includes(res.status)) {
      dispatch({ type: CREATE_PRO_MANAGER_SUCCESS });
    }
  } catch (error) {
    dispatch({
      type: CREATE_PRO_MANAGER_FAIL
    });
  }
};
