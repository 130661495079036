import React, { Fragment } from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Dashboardicon from "#/assets/dashboard.svg";
import MartIcon from "#/assets/mart.svg";
import Dashboardicon2 from "#/assets/timeline.svg";
import Dashboardicon3 from "#/assets/wallet.svg";
import Dashboardicon4 from "#/assets/settings.svg";
import Dashboardicon11 from "#/assets/sidebar svg/plus (1).svg";
import Dashboardicon5 from "#/assets/sidebar svg/home-run.svg";
import ImageLoader from "../ui-components/image-component";

const domain = window.location.hostname;

export const GeneralSidebar = ({ ActiveToggle }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <Fragment>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/dashboard"
          onClick={() => ActiveToggle("DASHBOARD")}>
          <ImageLoader
            src={Dashboardicon}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>DASHBOARD</span>
        </NavLink>
      </li>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/listing"
          onClick={() => ActiveToggle("LISTING")}>
          <ImageLoader
            src={Dashboardicon2}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>LISTING</span>
        </NavLink>
      </li>
      {domain !== "cloud.liveizy.com" ? (
        <li className="center">
          <NavLink
            activeClassName="activeClass"
            to="/estate-management/dashboard"
            onClick={() => ActiveToggle("ESTATE MANAGEMENT")}>
            <ImageLoader
              src={Dashboardicon5}
              alt="dashboard icon"
              className="sidebar-icon"
            />
            <span>ESTATE MANAGEMENT</span>
          </NavLink>
        </li>
      ) : null}
      {domain !== "cloud.liveizy.com" ? (
        <li className="center">
          <NavLink
            activeClassName="activeClass"
            to="/association"
            onClick={() => ActiveToggle("Association")}>
            <ImageLoader
              src={Dashboardicon5}
              alt="dashboard icon"
              className="sidebar-icon"
            />
            <span>ASSOCIATION</span>
          </NavLink>
        </li>
      ) : null}
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/wallet"
          onClick={() => ActiveToggle("WALLET")}>
          <ImageLoader
            src={Dashboardicon3}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>WALLET</span>
        </NavLink>
      </li>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/izymart"
          onClick={() => ActiveToggle("IZYMART")}>
          <ImageLoader src={MartIcon} alt="dashboard icon" className="sidebar-icon" />
          <span className="tw-uppercase">IzyMart</span>
        </NavLink>
      </li>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/account-setting"
          onClick={() => ActiveToggle("ACCOUNT SETTINGS")}>
          <ImageLoader
            src={Dashboardicon4}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>ACCOUNT SETTINGS</span>
        </NavLink>
      </li>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/referral"
          onClick={() => ActiveToggle("REFERRAL")}>
          <ImageLoader
            src={Dashboardicon11}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>REFERRAL</span>
        </NavLink>
      </li>
    </Fragment>
  );
};

export const PropertyManagerSidebar = ({ ActiveToggle }) => {
  const remindersExist = useSelector((state) => state.reminder.remindersExist);
  const tenantsUnderPm = useSelector((state) => state.tenant.tenantsUnderPm);
  const tenantsRequests = useSelector((state) => state.booking.tenantsRequests);
  const role = useSelector((state) => state.auth.user.roles);
  const { user } = useSelector((state) => state.auth);
  return (
    <Fragment>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/dashboard"
          onClick={() => ActiveToggle("DASHBOARD")}>
          <ImageLoader
            src={Dashboardicon}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>DASHBOARD</span>
        </NavLink>
      </li>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/listing"
          onClick={() => ActiveToggle("LISTING")}>
          <ImageLoader
            src="/assets/dashboard/listing.svg"
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>LISTING</span>
        </NavLink>
      </li>

      <li className="center">
        <Link to="#" className="has-arrow">
          <ImageLoader
            src="/assets/dashboard/property.svg"
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>PROPERTY</span>
        </Link>
        <ul className="submenu activeClass">
          <li>
            <NavLink
              activeClassName="activeClass-submenu"
              to="/property/list"
              onClick={() => ActiveToggle("PROPERTIES")}>
              All property
            </NavLink>
          </li>
          {tenantsRequests.length > 0 && (
            <li>
              <NavLink
                activeClassName="activeClass-submenu"
                to="/property/application"
                onClick={() => ActiveToggle("PROPERTIES")}>
                Applications
              </NavLink>
            </li>
          )}

          {/* <li>
            <NavLink activeClassName="activeClass-submenu" to="/income/dashboard" onClick={() => ActiveToggle("PROPERTIES")}>
              Income
            </NavLink>
          </li> */}
          {
            /*tenantsUnderPm.length > 0 && */ <li>
              <NavLink
                activeClassName="activeClass-submenu"
                to="/tenant/detail"
                onClick={() => ActiveToggle("PROPERTIES")}>
                Tenants
              </NavLink>
            </li>
          }

          {
            /*remindersExist.length > 0 && */ <li>
              <NavLink
                activeClassName="activeClass-submenu"
                to="/property/subscription/lirem"
                onClick={() => ActiveToggle("PROPERTIES")}>
                Subscription Details
              </NavLink>
            </li>
          }
          {
            /*remindersExist.length > 0 && */ <li>
              <NavLink
                activeClassName="activeClass-submenu"
                to="/property/members"
                onClick={() => ActiveToggle("PROPERTIES")}>
                Property Team
              </NavLink>
            </li>
          }
          {/* {role.includes("Agent") && (
                        <li>
                            <NavLink
                                to="/property/lpms"
                                activeClassName="activeClass-submenu"
                                onClick={() => ActiveToggle("PROPERTIES")}>
                                LPMS
                            </NavLink>
                        </li>
                    )} */}

          {/* <li>
            <NavLink to="#" onClick={() => ActiveToggle("PROPERTIES")}>
              Property Owner
            </NavLink>
          </li> */}
        </ul>
      </li>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/wallet"
          onClick={() => ActiveToggle("WALLET")}>
          <ImageLoader
            src={Dashboardicon3}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>WALLET</span>
        </NavLink>
      </li>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/izymart"
          onClick={() => ActiveToggle("IZYMART")}>
          <ImageLoader src={MartIcon} alt="dashboard icon" className="sidebar-icon" />
          <span className="tw-uppercase">IzyMart</span>
        </NavLink>
      </li>
      {/* <li className="center">
                <NavLink activeClassName="activeClass" to="/wallet" onClick={() => ActiveToggle("IZYMART")}>
                    <ImageLoader src="/assets/dashboard/izymart.svg" alt="dashboard icon" className="sidebar-icon" />
                    <span>IZYMART</span>
                </NavLink>
            </li> */}
      {/* <li className="center">
                <NavLink activeClassName="activeClass" to="/wallet" onClick={() => ActiveToggle("ASSOCIATION")}>
                    <ImageLoader
                        src="/assets/dashboard/association.svg"
                        alt="dashboard icon"
                        className="sidebar-icon"
                    />
                    <span>ASSOCIATION</span>
                </NavLink>
            </li> */}
      {domain !== "cloud.liveizy.com" ? (
        <li className="center">
          <NavLink
            activeClassName="activeClass"
            to="/estate-management/dashboard"
            onClick={() => ActiveToggle("ESTATE")}>
            <ImageLoader
              src="/assets/dashboard/estate.svg"
              alt="dashboard icon"
              className="sidebar-icon"
            />
            <span>ESTATE</span>
          </NavLink>
        </li>
      ) : null}
      {domain !== "cloud.liveizy.com" ? (
        <li className="center">
          <NavLink
            activeClassName="activeClass"
            to="/association"
            onClick={() => ActiveToggle("Association")}>
            <ImageLoader
              src={Dashboardicon5}
              alt="dashboard icon"
              className="sidebar-icon"
            />
            <span>ASSOCIATION</span>
          </NavLink>
        </li>
      ) : null}
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/account-setting"
          onClick={() => ActiveToggle("ACCOUNT SETTINGS")}>
          <ImageLoader
            src={Dashboardicon4}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>ACCOUNT SETTINGS</span>
        </NavLink>
      </li>
      <li className="center">
        <NavLink
          activeClassName="activeClass"
          to="/referral"
          onClick={() => ActiveToggle("REFERRAL")}>
          <ImageLoader
            src={Dashboardicon11}
            alt="dashboard icon"
            className="sidebar-icon"
          />
          <span>REFERRAL</span>
        </NavLink>
      </li>
    </Fragment>
  );
};
