import CustomBtn from "#/components/buttons/CustomBtn";
import SubHeader from "#/dashboard/components/SubHeader";
import RentalBillsList from "./RentalBillsList";
import { CircularProgress, styled } from "@mui/material";
import { useHistory } from "react-router-dom";

import useFetch from "#/hooks/util-hooks/useFetch";

const OtherPaymentsSection = ({ apartmentId }) => {
  const history = useHistory();

  const goToPayment = () => {
    history.push(`/payment-history/${apartmentId}`);
  };

  const { data, loading } = useFetch(
    apartmentId ? `/bill/get_apartment_bills/${apartmentId}` : null
  );

  return (
    <Container>
      <h3>
        Select the bills you want to pay for, click Generate Invoice to generate an
        invoice to complete payment
      </h3>
      <SubHeader
        title={"Payments"}
        component={<CustomBtn onClick={goToPayment}>View Payment History</CustomBtn>}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <RentalBillsList apartmentId={apartmentId} billsList={data?.data} />
      )}
    </Container>
  );
};

export default OtherPaymentsSection;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1.21238rem;
  padding: 3rem 2.5rem;

  h3 {
    text-align: center;
    font-style: italic;
    color: #c8202d;
  }

  @media (max-width: 420px) {
    padding: 1rem;
  }
`;
