import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect, useRouteMatch } from "react-router-dom";
import { usePropertyStyles } from "./createPropAndApart.styles";
import Stepper from "./subComponents/customStepper/stepper.component";
import PropertyForm from "#/components/propertyForm/propertyForm";
import ApartmentForm from "#/components/apartmentForm/apartmentForm";
import BackComponent from "#/components/propertycomponents/back-component";
import usePropertyForm from "#/hooks/propertyservice-hooks/usePropertyForm";
import useApartmentForm from "#/hooks/propertyservice-hooks/useApartmentForm";
import { useEffect } from "react";
import { clearCreatedTenant } from "#/store/actions/tenant.actions";

const Property = () => {
    const dispatch = useDispatch();
    //--------------------------------------------------
    //Store
    //--------------------------------------------------
    const { allPropertyInfo } = useSelector((state) => state.property);

    //--------------------------------------------------
    //State
    //--------------------------------------------------
    // const [currentStep, setCurrentStep] = useState(0); //Specify which step(page) we are on
    let currentStep = 0;
    //--------------------------------------------------
    //Helper hooks
    //--------------------------------------------------
    const { path, url } = useRouteMatch();
    const matchesFirstRoute = useRouteMatch(`${url}/property`);
    const matchesSecondRoute = useRouteMatch(`${url}/apartment`);
    const classes = usePropertyStyles();
    const { propertyFormHelpers } = usePropertyForm(false);
    const { apartmentFormHelpers } = useApartmentForm(allPropertyInfo);

    const {
        propertyId,
        propertyCoverImage,
        propertyCoverImagePreview,
        handleSetCoverImage,
        propertyName,
        handleSetPropertyName,
        addressDropdownHelpers,
        addMultipleImagesHelpers,
        errors,
        handleSubmitProperty,
        creatingProperty,
        propertyTypeHelpers,
        createPropertyAs
    } = propertyFormHelpers;

    const {
        apartmentId,
        apartmentStatusHelpers,
        apartmentPaymentFreqHelpers,
        apartmentFormFields,
        apartmentPositionHelpers,
        handleOnChangeApartmentInput,
        addMultipleApartmentImagesHelpers,
        creatingApartment,
        advertStatus,
        handleSetAdvertStatus,
        handleCreateApartment,
        apartDetailsDropdownHelpers,
        apartmentErrors,
        apartmentFloorHelpers
    } = apartmentFormHelpers;

    useEffect(() => {
        dispatch(clearCreatedTenant());
    }, []);

    //Control the active state of the stepper
    if (matchesFirstRoute) {
        currentStep = 0;
    } else if (matchesSecondRoute) {
        currentStep = 1;
    }

    //If someone tries to access the create apartment page withour first creating a property, then redirect them back to create property
    if (matchesSecondRoute && !allPropertyInfo) {
        return <Redirect to={`${path}/property`} />;
    }

    return (
        <>
            <div style={{ marginBottom: "20px", display: "inline-block" }}>
                <BackComponent />
            </div>
            <div className={classes.wrapper}>
                {/* Stepper */}
                <div className={classes.stepperWrapper}>
                    <Stepper currentStep={currentStep} />
                </div>
                <form>
                    <div>
                        <Route path={`${path}/property`} exact>
                            <PropertyForm
                                propertyId={propertyId}
                                handleSetCoverImage={handleSetCoverImage}
                                propertyCoverImagePreview={propertyCoverImagePreview}
                                propertyCoverImage={propertyCoverImage}
                                handleSetPropertyName={handleSetPropertyName}
                                propertyName={propertyName}
                                addressDropdownHelpers={addressDropdownHelpers}
                                handleSubmitProperty={handleSubmitProperty}
                                creatingProperty={creatingProperty}
                                multipleImagesHelpers={addMultipleImagesHelpers}
                                errors={errors}
                                propertyTypeHelpers={propertyTypeHelpers}
                                createPropertyAs={createPropertyAs}
                            />
                        </Route>

                        <Route path={`${path}/apartment`} exact>
                            <ApartmentForm
                                allPropertyInfo={allPropertyInfo}
                                handleSetCoverImage={handleSetCoverImage}
                                propertyCoverImagePreview={propertyCoverImagePreview}
                                apartmentId={apartmentId}
                                apartmentStatusHelpers={apartmentStatusHelpers}
                                apartmentPaymentFreqHelpers={apartmentPaymentFreqHelpers}
                                apartmentFormFields={apartmentFormFields}
                                apartmentPositionHelpers={apartmentPositionHelpers}
                                handleOnChangeApartmentInput={handleOnChangeApartmentInput}
                                addMultipleApartmentImagesHelpers={addMultipleApartmentImagesHelpers}
                                creatingApartment={creatingApartment}
                                advertStatus={advertStatus}
                                handleSetAdvertStatus={handleSetAdvertStatus}
                                handleCreateApartment={handleCreateApartment}
                                apartDetailsDropdownHelpers={apartDetailsDropdownHelpers}
                                apartmentErrors={apartmentErrors}
                                apartmentFloorHelpers={apartmentFloorHelpers}
                            />
                        </Route>

                        <Route path={path} exact>
                            <Redirect to={`${path}/property`} />
                        </Route>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Property;
