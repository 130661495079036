import ToggleSwitchWithLabel from "#/components/toggleSwitch/ToggleSwitchWithLabel";
import PaperContainer from "../paperContainer/Index";
import { styled } from "@mui/material";
// import YoutubeLinkIcon from "#/components/link-icons/YoutubeLinkIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_ROUTES } from "#/constants/routes";
import useFetch from "#/hooks/util-hooks/useFetch";
import MonthlyRentStatusActions from "./MonthlyRentStatusActions";

const REJECTED = "rejected";
const REVIEW = "review";
const VERIFIED = "verified";
const ACCEPTED = "accepted";
const PENDING = "pending";
const NONE = "none";

const MonthlyRentStatusComponent = ({ details, view, apartmentId }) => {
  const history = useHistory();

  const { data } = useFetch(
    apartmentId ? `/bill/get_apartment_bills/${apartmentId}` : null
  );

  // IF HOUSE RENT BILL IS PAID
  let isHouseRentPaid = false;

  if (data?.data) {
    for (const bill of data?.data) {
      if (bill.name === "House Rent" && bill.status === "paid") {
        isHouseRentPaid = true;
        break; // Stop iterating
      }
    }
  }

  // IF LR_STATUS IS COMPLETED-------have to work on this
  let showRenew = false;

  // IF EXISTING BILL LENGTH IS UPTO 11 AND ALL PAID
  const monthly_rent_bills =
    data?.data?.filter((bill) => {
      return bill.name === "Monthly Rent";
    }) || []; // Add a fallback to an empty array

  if (
    monthly_rent_bills.length >= 11 && // Check if there are at least 11 bills
    monthly_rent_bills.every((bill) => bill.status === "paid") // Check if the status of all bills is 'paid'
  ) {
    showRenew = true;
    // Send renew request
    // sendRenewRequest();
  }

  const {
    monthly_rent_status_stage_1: stageOne,
    monthly_rent_status_stage_2: stageTwo,
    laid,
    review_stage_1,
    is_renew
  } = details || {};

  let statusColor;
  let statusText;

  const goToAgreements = () => history.push(APP_ROUTES.dashboard.monthlyRentAgreement);
  const goToActivation = () => history.push(APP_ROUTES.dashboard.monthlyRentActivation);
  const goToForm = () => history.push(APP_ROUTES.dashboard.monthlyRentForm);
  const goToRenew = () => history.push(APP_ROUTES.dashboard.monthlyRenew);

  switch (stageOne) {
    case "verified":
      statusColor = "#25CC22";
      statusText = "Pre-qualification (Passed)";
      break;
    case PENDING:
      statusColor = "#FAA82D";
      statusText = "Pre-qualification (In Progress)";
      break;
    case REVIEW:
      statusColor = "#1b4db8";
      statusText = "Pre-qualification (Review)";
      break;
    case REJECTED:
      statusColor = "#FF0000";
      statusText = "Pre-qualification (Failed)";
      break;
    default:
      break;
  }

  switch (stageTwo) {
    case ACCEPTED:
      statusColor = "#25CC22";
      statusText = "All cleared for Activation";
      break;
    case PENDING:
      statusColor = "#25CC22";
      statusText = "Activation Ongoing";
      break;
    default:
      break;
  }

  return (
    <MonthlyRentDiv>
      <Wrapper>
        <ToggleDiv color={statusColor}>
          <ToggleSwitchWithLabel
            color={statusColor}
            checked={true}
            label={"Monthly Rent"}
          />
          <em>{statusText}</em>
        </ToggleDiv>

        {view !== "tenant" && (
          <MonthlyRentStatusActions
            stageOne={stageOne}
            stageTwo={stageTwo}
            review_stage_1={review_stage_1}
            laid={laid}
            goToForm={goToForm}
            goToActivation={goToActivation}
            goToRenew={goToRenew}
            goToAgreements={goToAgreements}
            is_renew={is_renew}
            isHouseRentPaid={isHouseRentPaid}
            REVIEW={REVIEW}
            VERIFIED={VERIFIED}
            ACCEPTED={ACCEPTED}
            NONE={NONE}
            view={view}
          />
        )}
      </Wrapper>
    </MonthlyRentDiv>
  );
};

export default MonthlyRentStatusComponent;

const Wrapper = styled(PaperContainer)`
  padding: 1.5rem 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;

const ToggleDiv = styled("div")`
  display: flex;
  flex-direction: column;

  em {
    color: ${(props) => props.color};
    font-size: 0.8rem;
    margin-left: 40px;
  }
`;

const MonthlyRentDiv = styled("div")`
  width: 35% !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 750px) {
    width: 100% !important;
  }
`;
