import { useState, useCallback } from "react";

const useArrowDropdown = (options) => {
    const [dropdownOptions, setDropdownOptions] = useState(options);
    const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]); //This is an object

    const handleSetSelectedOption = useCallback(
        (e) => {
            const option = dropdownOptions.find((item) => {
                return item.mainValue === e.target.id;
            });

            setSelectedOption(option);
        },
        [dropdownOptions]
    );

    return {
        dropdownOptions,
        selectedOption,
        handleSetSelectedOption,
        setSelectedOption,
        setDropdownOptions
    };
};

export default useArrowDropdown;
