import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router-dom";

const Back = (props) => {
    const Styles = makeStyles((theme) => ({
        root: {
            fontWeight: 900,
            marginBottom: "20px",
            fontSize: "1.1rem",
            fontFamily: "Open Sans-Semibold, sans-serif",
            cursor: "pointer",
            opacity: 0.5,
            [theme.breakpoints.down("xs")]: {
                fontSize: 16,
                transform: "scale(0.8)"
            }
        }
    }));

    const classes = Styles();

    const handleRedirect = () => {
        if (props.redirectUrl) {
            props.history.push(`${props.redirectUrl}`);
            return;
        } else {
            props.history.goBack();
        }
    };
    return (
        <div className={classes.root} onClick={handleRedirect}>
            <ArrowBackIosIcon />
            Back
        </div>
    );
};
export default withRouter(React.memo(Back));
