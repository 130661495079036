import SubHeadingUnderline from "#/association/components/SubHeadingUnderline";
import SubtitleSubtext from "#/association/components/SubtitleSubtext";
import BlueGradientJumbotron from "#/association/components/jumbotrons/BlueGradientJumbotron";
import { toCurrency } from "#/components/utils/util";
import MonthlyRentBlock from "#/dashboard/components/monthlyrent/MonthlyRentBlock";
import React from "react";
import { useSelector } from "react-redux";

const PropertyDashboard = ({ view }) => {
  const monthlyRentDetail = useSelector((store) => store.tenant.monthlyRent);

  return (
    <div>
      <SubHeadingUnderline text="Property Details" />
      <BlueGradientJumbotron className="blue-banner" marginTop={3}>
        <div className="custom-grid">
          <SubtitleSubtext title={"Total Apartment"} text="256" />
          <SubtitleSubtext title={"Total Rent"} text="256" />
          <SubtitleSubtext title={"Total Occupied"} text="256" />
          <SubtitleSubtext title={"Total Vacant"} text={`0`} />
          <SubtitleSubtext title={"Last Rent Paid"} text={toCurrency(7500000)} />
          <SubtitleSubtext title={"Rent Payable"} text={toCurrency(120000000000)} />
          <SubtitleSubtext title={"Received Rent"} text={toCurrency(240000000)} />
          <SubtitleSubtext title={"Paid Out"} text={toCurrency(1200000000000)} />
        </div>
      </BlueGradientJumbotron>

      <div className="tw-flex tw-flex-col tw-gap-7 tw-mt-16">
        <SubHeadingUnderline text="Monthly Rent" />
        <MonthlyRentBlock
          view={view}
          details={monthlyRentDetail[0]}
          monthlyRentDetails={monthlyRentDetail}
        />
      </div>
    </div>
  );
};

export default PropertyDashboard;
