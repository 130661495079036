import React, { memo, useState, useCallback, useRef } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import PropTypes from "prop-types";

import { useStyles } from "./arrowDropdown.styles";

const ArrowDropdown = ({
  name,
  label,
  placeholder,
  type = "text",
  options,
  error,
  selectedOption,
  handleSetSelectedOption,
  styles = {},
  boxClasses
}) => {
  const [showDropDown, setShowDropdown] = useState(false);
  const classes = useStyles(showDropDown);
  const anchorRef = useRef(null);

  const handleShowDropdown = useCallback(() => {
    setShowDropdown(!showDropDown);
  }, [showDropDown]);

  const onSelectOption = useCallback((e) => {
    setShowDropdown(false);
    handleSetSelectedOption(e);
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setShowDropdown(false);
  };
  return (
    <div className={classes.inputField} style={styles}>
      <label htmlFor={name} className={boxClasses}>
        {label}
      </label>
      <div className="input-wrapper" onClick={handleShowDropdown}>
        <input
          name={name}
          type={type}
          value={selectedOption?.displayValue}
          placeholder={placeholder}
          ref={anchorRef}
        />
        <div className="input-wrapper__dropdown"></div>
        <ClickAwayListener onClickAway={handleClose}>
          <ul className="input-wrapper__options" onClick={onSelectOption}>
            {options.slice(1).map((option) => {
              const { displayValue, mainValue } = option;

              return (
                <li key={mainValue} id={mainValue}>
                  {displayValue}
                </li>
              );
            })}
          </ul>
        </ClickAwayListener>
      </div>
      {error && (
        <div name={name} className={classes.errorMessage}>
          Field is required
        </div>
      )}
    </div>
  );
};

ArrowDropdown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      mainValue: PropTypes.string,
      displayValue: PropTypes.string
    })
  ),
  error: PropTypes.bool,
  showDropDown: PropTypes.bool,
  handleShowDropdown: PropTypes.func,
  selectedOption: PropTypes.shape({
    mainValue: PropTypes.string,
    displayValue: PropTypes.string
  }),
  handleSetSelectedOption: PropTypes.func
};

export default memo(ArrowDropdown);
