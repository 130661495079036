import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CircularProgress } from "@material-ui/core";
import { format } from "date-fns";
import CustomInput from "../customInput/customInput";
import ArrowDropdown from "../../../customDropdowns/arrowDropdown/arrowDropdown";

import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 40,
    marginTop: 40
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const CreateOtherCharges = ({
  apartmentCharges,
  isForEdit = false,
  classes,
  apartmentChargesHelpers
}) => {
  const accordionClasses = useStyles();
  const {
    handleOnChangeInput,
    isCreating,
    handleSetIsCreatingCharge,
    errors,
    chargeName,
    chargeAmount,
    paymentDate,
    dropdownOptions,
    paymentTermCharge,
    showDropDown,
    handleToggleDropdown,
    handleSetSelectedOption,
    setSelectedOption,
    handleSubmitCharge,
    loading,
    handleClearCharges,
    selectedCharge,
    deleting
  } = apartmentChargesHelpers;
  return (
    <div className={accordionClasses.root}>
      <Accordion onChange={handleSetIsCreatingCharge}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <p style={{ color: "#043b5c" }} className={classes.heading}>
            {apartmentCharges.length
              ? `Charges (${apartmentCharges.length})`
              : "Create Charges"}
          </p>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.apartmentCharges}>
            <section className="other-charges">
              {
                <>
                  <div className="charge-fields">
                    <div className="charge-fields__wrapper">
                      <CustomInput
                        name="chargeName"
                        label="Name of Charge"
                        placeholder="agreement"
                        value={chargeName}
                        handleOnChange={handleOnChangeInput}
                        errors={errors.chargeName}
                        styles={{ marginBottom: "0px" }}
                      />
                    </div>
                    <div className="charge-fields__wrapper">
                      <CustomInput
                        name="chargeAmount"
                        label="Amount"
                        placeholder="100,000"
                        value={chargeAmount}
                        handleOnChange={handleOnChangeInput}
                        errors={errors.chargeAmount}
                        styles={{ marginBottom: "0px" }}
                      />
                    </div>
                    <div className="charge-fields__wrapper">
                      <ArrowDropdown
                        name="paymentTerm"
                        label="Payment Term"
                        placeholder="Payment Term"
                        options={dropdownOptions}
                        selectedOption={paymentTermCharge}
                        showDropDown={showDropDown}
                        handleShowDropdown={handleToggleDropdown}
                        handleSetSelectedOption={handleSetSelectedOption}
                        error={errors.paymentTermCharge}
                        styles={{ marginBottom: "0px" }}
                      />
                    </div>

                    <div className="charge-fields__wrapper">
                      <CustomInput
                        name="paymentDate"
                        label="Payment Date"
                        placeholder="04/09"
                        type="date"
                        value={paymentDate}
                        handleOnChange={handleOnChangeInput}
                        errors={errors.paymentDate}
                        styles={{ marginBottom: "0px" }}
                      />
                    </div>
                    <div className="charge-fields__wrapper">
                      <div className="call-to-action">
                        <div className="add" onClick={handleSubmitCharge}>
                          {loading ? (
                            <CircularProgress size={18} color="inherit" />
                          ) : (
                            <AddIcon className="add__icon" />
                          )}
                        </div>
                        <div className="cancel" onClick={handleClearCharges}>
                          <ClearIcon className="cancel__icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedCharge && (
                    <button
                      type="submit"
                      className={classes.btnRed}
                      onClick={(e) => handleSubmitCharge(e, true)}
                      style={{ marginTop: "10px" }}>
                      {deleting ? (
                        <CircularProgress size={18} color="inherit" />
                      ) : (
                        "Delete"
                      )}
                    </button>
                  )}
                </>
              }
            </section>
            <div style={{ marginTop: 30 }}>
              <h4 className="charge-cards-wrapper-title">Apartment Charges</h4>
              {apartmentCharges.length > 0 ? (
                <div className={classes.chargeCardsWrapper}>
                  {apartmentCharges.map((item) => {
                    const { amount, name, payment_term, payment_date } = item;
                    return (
                      <div
                        className={classes.chargeCard}
                        key={item._id}
                        onClick={() =>
                          apartmentChargesHelpers.handleSetChargeToEdit(item)
                        }>
                        <div className="left-column">
                          <h3 className="charge-name">{name}</h3>
                          <h4 className="charge-term">{payment_term}</h4>
                          <h4 className="charge-term">
                            {format(new Date(`${payment_date}`), "dd MMMM yyyy")}
                          </h4>
                        </div>
                        <h4 className="charge-amount">{`#${amount}`}</h4>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(CreateOtherCharges);
