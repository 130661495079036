import { logout } from "#/store/actions/auth.actions";
import store from "#/store/store";

const axios = require("axios");

const baseURL = process.env.REACT_APP_PROPERTY_SERVICE_BASE_URL;

export const propertyServiceApi = () => {
  const userData = JSON.parse(localStorage.getItem("data"));
  const permToken = localStorage.getItem("PERMISSION_TOKEN");

  const instance = axios.create({
    baseURL,
    headers: {
      Authorization: "Bearer " + (userData?.token || "") + " " + (permToken || "")
    }
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // handle Unauthorized error
      if (error.response.status === 401) {
        store.dispatch(logout()); // dispatch the logout action
        // console.log(error.response)
      } else {
        return Promise.reject(error);
      }
    }
  );
  return instance;
};

export const returnUserData = () => {
  const userData = JSON.parse(localStorage.getItem("data"));
  return userData.user;
};

export const returnEstateMember = () => {
  const member = JSON.parse(localStorage.getItem("ESTATE_MEMBER"));
  const memberRole = JSON.parse(localStorage.getItem("ESTATE_ROLE"));

  return { member, memberRole };
};

export const propertyServiceUrl = baseURL;
