import CustomBtn from "#/components/buttons/CustomBtn";
import FlexBetween from "#/components/flexboxes/FlexBetween";
import { APP_ROUTES } from "#/constants/routes";
import { alertError } from "#/store/actions/notification.actions";
import { Button, styled, IconButton } from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RentalPaymentTable from "./RentalPaymentTable";
import useRentalPaymentCart from "../../hooks/useRentalPaymentCart";
import { handleMakePayment } from "./utils";

// Generate invoice page, where handleMakePayment function is used
const RentalPayments = () => {
  const paymentDetails = JSON.parse(localStorage.getItem("RENTAL_PAYMENTS"));
  console.log(paymentDetails);

  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const billsList = paymentDetails?.rentalCart;

  const {
    rentalPaymentCart,
    handleSelectItem,
    partRent,
    setPartRent,
    totalAmount,
    partRentNumberValue
  } = useRentalPaymentCart(billsList);

  useEffect(() => {
    if (!paymentDetails) {
      dispatch(alertError("No Selected Payments"));
      return history.push(APP_ROUTES.dashboard.main);
    }
  }, [dispatch, history, paymentDetails]);

  const RedClearIcon = styled(ClearIcon)({
    color: "red"
  });

  const handleGenerateInvoice = () => {
    setLoading(true); // Set loading to true when generating invoice
    handleMakePayment(
      rentalPaymentCart,
      partRentNumberValue,
      paymentDetails,
      totalAmount,
      history,
      dispatch
    )
      .then(() => {
        setLoading(false);
        localStorage.removeItem("RENTAL_PAYMENTS");
      }) // Set loading to false on success
      .catch(() => setLoading(false)); // Set loading to false when API call is completed
  };

  return (
    <Container>
      <CloseContainer>
        <Button
          sx={{ fontSize: "0.75rem", padding: "0.5rem .5rem" }}
          // variant="outlined"
          color="primary"
          onClick={() => history.push(APP_ROUTES.dashboard.main)}>
          <IconButton>
            <RedClearIcon />
          </IconButton>
          CLOSE
        </Button>
      </CloseContainer>
      <RentalPaymentTable
        billsList={billsList}
        partRent={partRent}
        setPartRent={setPartRent}
        handleSelectBill={handleSelectItem}
      />

      <FlexBetween style={{ gap: "2rem" }}>
        <Button
          sx={{ fontSize: "0.75rem", padding: "0.5rem 3rem" }}
          variant="outlined"
          color="primary"
          onClick={() => history.push(APP_ROUTES.dashboard.main)}>
          Back
        </Button>
        <CustomBtn
          sx={{ fontSize: "0.75rem", padding: "0.5rem 3rem" }}
          onClick={handleGenerateInvoice}
          disabled={loading} // Disable button when loading
        >
          {loading ? <CircularProgress size={24} /> : "Confirm Invoice"}{" "}
          {/* Show CircularProgress when loading */}
        </CustomBtn>
      </FlexBetween>
      <VfdLogo>
        <img src="/assets/dashboard/vfd.svg" alt="Vfd Logo" />
      </VfdLogo>
    </Container>
  );
};

export default RentalPayments;

const Container = styled("div")`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 1rem 2rem;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 0;
    width: 100%;
  }
`;

const CloseContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: left;
  align-items: center;
  color: "red";
`;

const VfdLogo = styled("div")`
  margin: 0 auto;
  max-width: 200px;

  img {
    width: 100%;
  }
`;

/* <IconButton
          sx={{ fontSize: "0.75rem", padding: "0.5rem .5rem" }}
          variant="outlined"
          color="primary"
          onClick={() => history.push(APP_ROUTES.dashboard.main)}>
          <ClearIcon />
          <RedClearIcon />
        </IconButton>
        CLOSE */

/* <CustomBtn
          sx={{ fontSize: "0.75rem", padding: "0.5rem 3rem" }}
          onClick={() =>
            handleMakePayment(
              rentalPaymentCart,
              partRentNumberValue,
              paymentDetails.apartmentId,
              totalAmount,
              history,
              dispatch
            )
          }>
          Confirm Payment
        </CustomBtn> */
