import SubHeader from "#/dashboard/components/SubHeader";
import { Button, styled } from "@mui/material";
import ApartmentCard from "./ApartmentCard";
import MonthlyRentStatusComponent from "#/dashboard/components/monthlyrent/MonthlyRentStatusComponent";
import { APP_ROUTES } from "#/constants/routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useFetch from "#/hooks/util-hooks/useFetch";
import MonthlyRentStatusActions from "#/dashboard/components/monthlyrent/MonthlyRentStatusActions";
import { useEffect } from "react";

const VERIFIED = "verified";
const REVIEW = "review";
const ACCEPTED = "accepted";
const NONE = "none";

export default function ApartmentDetails({
  record,
  view,
  details,
  setStatusAction,
  monthlyRentDetails
}) {
  const apartmentId = record?.apartment_id?.apartment_id;
  const history = useHistory();

  const { data } = useFetch(
    apartmentId ? `/bill/get_apartment_bills/${apartmentId}` : null
  );

  const goToAgreements = () => history.push(APP_ROUTES.dashboard.monthlyRentAgreement);
  const goToActivation = () => history.push(APP_ROUTES.dashboard.monthlyRentActivation);
  const goToRenew = () => history.push(APP_ROUTES.dashboard.monthlyRenew);
  const goToForm = () => history.push(APP_ROUTES.dashboard.monthlyRentForm);

  let isHouseRentPaid = false;

  if (data?.data) {
    for (const bill of data?.data) {
      if (bill.name === "House Rent" && bill.status === "paid") {
        isHouseRentPaid = true;
        break; // Stop iterating
      }
    }
  }

  const {
    monthly_rent_status_stage_1: stageOne,
    monthly_rent_status_stage_2: stageTwo,
    laid,
    is_renew,
    review_stage_1
  } = details || {};

  // Determine if MonthlyRentStatusActions should be rendered
  const showMonthlyRentStatusActions =
    monthlyRentDetails.length < 1 ||
    (stageOne === VERIFIED && laid && stageTwo === NONE) ||
    (stageOne === VERIFIED &&
      laid &&
      stageTwo === ACCEPTED &&
      isHouseRentPaid === false) ||
    (stageOne === VERIFIED && laid && stageTwo === ACCEPTED && is_renew === true);

  // Update statusAction based on showMonthlyRentStatusActions using useEffect
  useEffect(() => {
    setStatusAction(showMonthlyRentStatusActions);
  }, [showMonthlyRentStatusActions, setStatusAction]);

  return (
    <Container>
      <SubHeader title={"Apartment Details"} component={<ViewManagerBtn />} />
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex-1">&nbsp;</div>
        {view === "tenant" && monthlyRentDetails.length > 0 && (
          <MonthlyRentStatusComponent
            apartmentId={apartmentId}
            view={view}
            details={details}
          />
        )}
      </div>
      <ApartmentCard details={record?.apartment_id} />
      <MonthlyRentStatusActions
        stageOne={stageOne}
        stageTwo={stageTwo}
        review_stage_1={review_stage_1}
        laid={laid}
        goToForm={goToForm}
        goToActivation={goToActivation}
        goToRenew={goToRenew}
        goToAgreements={goToAgreements}
        is_renew={is_renew}
        isHouseRentPaid={isHouseRentPaid}
        REVIEW={REVIEW}
        VERIFIED={VERIFIED}
        ACCEPTED={ACCEPTED}
        NONE={NONE}
        view={view}
      />
    </Container>
  );
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

const ViewManagerBtn = () => (
  <Button
    sx={{ fontSize: "0.75rem", padding: "0.5rem 1.3rem", border: "2px solid" }}
    variant="outlined"
    color="primary">
    VIEW MANAGER DETAILS
  </Button>
);
