import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch, batch } from "react-redux";
import { format } from "date-fns";

import ApartmentForm from "#/components/apartmentForm/apartmentForm";
import BackComponent from "#/components/propertycomponents/back-component";

import useApartmentForm from "#/hooks/propertyservice-hooks/useApartmentForm";
import { getApartmentById } from "#/store/actions/apartment.actions";

import {
  OPTIONS_AVAILABILITY_OF_PROPERTY,
  OPTIONS_PAYMENT_FREQUENCY,
  APARTMENT_POSITON,
  APARTMENT_FLOORS
} from "#/components/apartmentForm/apartmentForm.constants";
import { getApartmentCharges } from "#/store/actions/charges.actions";
import { getApartmentSchedules } from "#/store/actions/schedules.action";
import { getPropertyById } from "#/store/actions/property.actions";
import { getTenant } from "#/store/actions/tenant.actions";
import { returnUserData } from "#/apis/propertyServiceApi";

const EditApartment = () => {
  const dispatch = useDispatch();
  const { liveizy_id } = returnUserData();
  const history = useHistory();
  const initialPropValues = useSelector((state) => state.property.propertyById);
  const initialApartValues = useSelector((state) => state.apartment.apartmentById); //Used when editing a property
  const apartmentCharges = useSelector((state) => state.charges.charges); //Used when editing a property
  const apartmentSchedules = useSelector((state) => state.schedules.apartmentSchedules);

  const { apartmentFormHelpers } = useApartmentForm(initialPropValues, true);
  const { apartmentId: urlApartmentId, propertyId: urlPropertyId } = useParams();

  const {
    apartmentId,
    apartmentStatusHelpers,
    apartmentPaymentFreqHelpers,
    apartmentFormFields,
    apartmentPositionHelpers,
    handleOnChangeApartmentInput,
    addMultipleApartmentImagesHelpers,
    creatingApartment,
    advertStatus,
    handleSetAdvertStatus,
    handleCreateApartment,
    apartDetailsDropdownHelpers,
    apartmentErrors,
    setApartmentFormFields,
    setAdvertStatus,
    previouslyUploadedImages,
    setPreviouslyUploadedImages,
    handleRemovePreviouslyUploadedImage,
    apartmentFloorHelpers,
    updatingApartment
  } = apartmentFormHelpers;

  useEffect(() => {
    if (initialApartValues) {
      const {
        advert_status,
        apartment_position,
        entrance,
        extra_description,
        floor,
        payment_term,
        price,
        rent_payment_date,
        status,
        apartment_images,
        no_of_bedrooms,
        no_of_ensuite,
        no_of_sitting_room,
        no_of_toilet,
        extras
      } = initialApartValues;

      //set some fields default
      setApartmentFormFields({
        rent: price,
        // floor: floor,
        entrance: entrance,
        otherDesc: extra_description,
        apartmentStatus: "",
        rentDate: rent_payment_date
          ? format(new Date(`${rent_payment_date}`), "yyyy-MM-dd")
          : ""
      });

      //set default advert status
      setAdvertStatus(advert_status);

      //set default status of apartment
      const selectedApartmentStatus = OPTIONS_AVAILABILITY_OF_PROPERTY.filter((item) => {
        return item.mainValue === status;
      });
      apartmentStatusHelpers.setSelectedOption(selectedApartmentStatus[0]);

      //set default payment frequency
      const selectedPaymentFrequency = OPTIONS_PAYMENT_FREQUENCY.filter((item) => {
        return item.mainValue === payment_term;
      });
      apartmentPaymentFreqHelpers.setSelectedOption(selectedPaymentFrequency[0]);

      //set default apartment position
      const selectedApartmentPosition = APARTMENT_POSITON.filter((item) => {
        return item.mainValue === apartment_position;
      });
      apartmentPositionHelpers.setSelectedOption(selectedApartmentPosition[0]);

      //set default apartment Floor
      const selectedApartmentFloor = APARTMENT_FLOORS.filter((item) => {
        return item.mainValue === floor;
      });

      apartmentFloorHelpers.setSelectedOption(
        selectedApartmentFloor[0] || {
          mainValue: "Select Floor",
          displayValue: "Select Floor"
        }
      );

      //set default other property images
      let otherImages = [];
      for (let i = 0; i < apartment_images?.length; i++) {
        otherImages.push(apartment_images[i]);
      }
      setPreviouslyUploadedImages([...otherImages]);

      apartDetailsDropdownHelpers.setOtherFacilities((prevState) => {
        return {
          ...prevState
        };
      });

      apartDetailsDropdownHelpers.setMainFacilities((prevState) => {
        return {
          ...prevState,
          bedrooms: no_of_bedrooms,
          bathrooms: no_of_toilet,
          sittingRooms: no_of_sitting_room,
          ensuites: no_of_ensuite
        };
      });
    }
  }, [initialApartValues]);

  useEffect(() => {
    batch(() => {
      dispatch(getApartmentById(urlApartmentId));
      dispatch(getPropertyById(urlPropertyId));
      dispatch(getPropertyById(urlPropertyId, "charges", "tenant"));
      dispatch(getApartmentCharges(urlApartmentId));
      dispatch(getApartmentSchedules(urlApartmentId));
      dispatch(getTenant(urlApartmentId));
    });
  }, [urlApartmentId, urlPropertyId]);

  if (
    initialPropValues &&
    initialPropValues.owner !== liveizy_id &&
    initialPropValues.property_manager !== liveizy_id &&
    !initialPropValues.otherManagers.includes(liveizy_id)
  ) {
    history.push("/property/list");
    return null;
  }

  return (
    <>
      <div style={{ marginBottom: "20px", display: "inline-block" }}>
        <BackComponent />
      </div>
      <ApartmentForm
        allPropertyInfo={initialPropValues}
        apartmentId={urlApartmentId}
        apartmentStatusHelpers={apartmentStatusHelpers}
        apartmentPaymentFreqHelpers={apartmentPaymentFreqHelpers}
        apartmentFormFields={apartmentFormFields}
        apartmentPositionHelpers={apartmentPositionHelpers}
        handleOnChangeApartmentInput={handleOnChangeApartmentInput}
        addMultipleApartmentImagesHelpers={addMultipleApartmentImagesHelpers}
        creatingApartment={creatingApartment}
        advertStatus={advertStatus}
        handleSetAdvertStatus={handleSetAdvertStatus}
        handleCreateApartment={handleCreateApartment}
        apartDetailsDropdownHelpers={apartDetailsDropdownHelpers}
        apartmentErrors={apartmentErrors}
        isForEdit={true}
        apartmentCharges={apartmentCharges}
        apartmentSchedules={apartmentSchedules}
        previouslyUploadedImages={previouslyUploadedImages}
        handleRemovePreviouslyUploadedImage={handleRemovePreviouslyUploadedImage}
        apartmentFloorHelpers={apartmentFloorHelpers}
        updatingApartment={updatingApartment}
        styles={{ borderRadius: "20px" }}
      />
    </>
  );
};

export default EditApartment;
