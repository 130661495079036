import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Modal,
  makeStyles,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TablePagination from "@material-ui/core/TablePagination";
import { propertyTeamStyles } from "./styles";
import PageHeader from "#/components/propertycomponents/page-header";
import { showNotifications } from "#/store/actions/notification.actions";
import PlansInfoModal from "./components/PlansInfoModal";
import SadFaceIconComponent from "#/components/ui-components/sadFaceIcon.component";
import AddButton from "#/components/propertycomponents/button-component";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { authServiceApi } from "#/apis/authServiceApi";

const PropertyTeam = () => {
  const [memberList, setMemberList] = useState([]);
  const [businessCompany, setBusinessCompany] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleCreateModal, setCreateModal] = useState(false);
  const [toggleBusinessModal, setBusinessModal] = useState(false);
  const [businessForm, setBusinessForm] = useState({
    legal_name: "",
    address: "",
    phone: ""
  });
  const [existingBusinessData, setExistingBusinessData] = useState(null);
  const [editFormData, setEditFormData] = useState({
    title: "",
    added_by: "",
    member_id: "",
    permissions: [
      { name: "bill", permissions: [] },
      { name: "apartment", permissions: [] },
      { name: "tenant", permissions: [] }
    ]
  });
  const [createFormData, setcreateFormData] = useState({
    title: "",
    added_by: "",
    member_id: "",
    permissions: [
      { name: "bill", permissions: [] },
      { name: "apartment", permissions: [] },
      { name: "tenant", permissions: [] }
    ]
  });

  const [currentModal, setCurrentModal] = useState("bothPlans");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleModalOpen = () => {
    setToggleModal(true);
  };

  const handleModalClose = () => {
    setToggleModal(false);
  };

  const handleCreateModalOpen = () => {
    setCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setCreateModal(false);
  };

  const handleBusinessModalOpen = () => {
    setBusinessModal(true);
    if (businessCompany) {
      // const [firstBusiness] = businessCompany; // Assuming you only need the first business
      setExistingBusinessData(businessCompany);
      setBusinessForm(businessCompany);
    }
  };

  const handleBusinessModalClose = () => {
    setBusinessModal(false);
  };

  const handleCreate = () => {
    //Check if businessCompany is populated
    if (businessCompany && Object.keys(businessCompany).length === 0) {
      dispatch(showNotifications("You have to create a company first", "error"));
    } else {
      handleCreateModalOpen();
    }
  };

  const handleCreateBusiness = () => {
    handleBusinessModalOpen();
  };

  const handleInputChangeBusiness = (e) => {
    const { name, value } = e.target;
    setBusinessForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleBusinessCreate = async (event) => {
    //Form submission logic
    event.preventDefault();
    try {
      //Decided if its an edit or create
      if (Object.keys(existingBusinessData).length != 0) {
        if (!businessForm.phone || !businessForm.legal_name || !businessForm.address) {
          dispatch(showNotifications("Please fill in all fields", "error"));
          return;
        }
        // Construct the data object
        const formData = {
          legal_name: businessForm.legal_name,
          address: businessForm.address,
          phone: businessForm.phone
        };
        const ownerId = existingBusinessData._id;

        // Make the POST request
        const response = await propertyServiceApi().patch(
          `/business?id=${ownerId}`,
          formData
        );
        dispatch(showNotifications("Business created successfully", "success"));
        handleBusinessModalClose();

        // Fetch the updated businesses list
        fetchUserBusinessFromAPI();
      } else {
        // Make sure all fields are filled
        if (!businessForm.phone || !businessForm.legal_name || !businessForm.address) {
          dispatch(showNotifications("Please fill in all fields", "error"));
          return;
        }

        // Construct the data object
        const formData = {
          legal_name: businessForm.legal_name,
          address: businessForm.address,
          phone: businessForm.phone,
          roles: userInfo.roles
        };

        // Make the POST request
        const response = await propertyServiceApi().post(`/business`, formData);
        dispatch(showNotifications("Business created successfully", "success"));
        handleBusinessModalClose();

        // Fetch the updated businesses list
        fetchUserBusinessFromAPI();
      }
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const handleInputChangeCreate = (e) => {
    const { name, value } = e.target;
    setcreateFormData({
      ...createFormData,
      [name]: value
    });
  };
  const handlePermissionChange = (permissionName, permissionType) => {
    setcreateFormData((prevFormData) => {
      const updatedPermissions = prevFormData.permissions.map((permission) => {
        if (permission.name === permissionName) {
          return {
            ...permission,
            permissions: permission.permissions.includes(permissionType)
              ? permission.permissions.filter((p) => p !== permissionType)
              : [...permission.permissions, permissionType]
          };
        }
        return permission;
      });
      return {
        ...prevFormData,
        permissions: updatedPermissions
      };
    });
  };

  const handleFormSubmitCreate = async (event) => {
    //Form submission logic
    event.preventDefault();
    try {
      // Make sure all fields are filled
      if (!createFormData.title || !createFormData.member_id) {
        dispatch(showNotifications("Please fill in all fields", "error"));
        return;
      }

      // Construct the data object
      const formData = {
        title: createFormData.title,
        added_by: createFormData.added_by,
        liveizy_id: createFormData.member_id,
        permissions: createFormData.permissions,
        business: businessCompany._id
      };

      // Make the POST request
      const response = await propertyServiceApi().post(`/account-member`, formData);
      dispatch(showNotifications("Members created successfully", "success"));
      handleCreateModalClose();
      // Fetch the updated member list
      fetchPropertiesFromAPI();
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };
  const handleEdit = (memberId) => {
    // Fetch member details and set to editFormData
    const member = memberList.find((m) => m._id === memberId);

    if (!member.permissions || !Array.isArray(member.permissions)) {
      // If member permissions are not defined or not an array, set an empty array
      member.permissions = [];
    }

    setEditFormData({
      title: member.title,
      added_by: member.added_by,
      member_id: member.member_id,
      _id: member._id,
      permissions: member.permissions.map((permission) => ({
        name: permission.name,
        permissions: [...permission.permissions]
      }))
    });

    handleModalOpen();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handlePermissionChangeEdit = (categoryIndex, permissionName) => {
    setEditFormData((prevFormData) => {
      const updatedPermissions = [...prevFormData.permissions];
      const permissionIndex = updatedPermissions[categoryIndex].permissions.indexOf(
        permissionName
      );
      if (permissionIndex === -1) {
        // Permission not found, add it
        updatedPermissions[categoryIndex].permissions.push(permissionName);
      } else {
        // Permission found, remove it
        updatedPermissions[categoryIndex].permissions.splice(permissionIndex, 1);
      }
      return {
        ...prevFormData,
        permissions: updatedPermissions
      };
    });
  };
  const handleFormSubmit = async (event) => {
    //Form submission logic
    event.preventDefault();
    try {
      // Make sure all fields are filled
      if (!editFormData.title || !editFormData.member_id) {
        dispatch(showNotifications("Please fill in all fields", "error"));
        return;
      }

      // Construct the data object
      const formData = {
        title: editFormData.title,
        added_by: editFormData.added_by,
        member_id: editFormData.member_id,
        id: editFormData._id,
        permissions: editFormData.permissions.map((permission) => ({
          name: permission.name,
          permissions: permission.permissions.filter((perm) => perm !== true)
        }))
      };

      // Make the POST request
      const response = await propertyServiceApi().patch(`/account-member`, formData);
      dispatch(showNotifications("Members updated successfully", "success"));
      handleModalClose();
      // Fetch the updated member list
      fetchPropertiesFromAPI();
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const handleDelete = async (memberId) => {
    // Placeholder for delete logic
    try {
      const param = {
        id: memberId
      };
      const response = await propertyServiceApi().delete(
        `/account-member/${memberId}`,
        param
      );
      dispatch(showNotifications("Members deleted successfully", "success"));
      // Fetch the updated member list
      fetchPropertiesFromAPI();
    } catch (error) {
      // Handle errors
      console.error("Error deleting member:", error);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          propertiesResponse,
          businessResponse,
          userInfoResponse
        ] = await Promise.all([
          fetchPropertiesFromAPI(),
          fetchUserBusinessFromAPI(),
          fetchUserFromAPI()
        ]);
        setMemberList(propertiesResponse.data.data);
        setBusinessCompany(businessResponse.data.data);
        setUserInfo(userInfoResponse.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.message;
        dispatch(showNotifications(errorMessage, "error"));
      }
    };

    fetchData();
  }, []);

  const fetchPropertiesFromAPI = async () => {
    try {
      const response = await propertyServiceApi().get(`/account-member`);
      setMemberList(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const fetchUserBusinessFromAPI = async () => {
    try {
      const response = await propertyServiceApi().get(`/business`);
      const responseData = response.data.data;
      if (responseData === null || typeof responseData !== "object") {
        // Handle the case where responseData is null or not an object
        setBusinessCompany([]); // Set to an empty array or handle differently based on your requirements
      } else {
        setBusinessCompany(responseData); // Set the state with the valid data
      }
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const fetchUserFromAPI = async () => {
    try {
      const response = await authServiceApi().get(`/user/me`);
      setUserInfo(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.message || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const classes = propertyTeamStyles();

  return (
    <div className={"property-team-page" + " " + classes.root} id="property-team-page">
      <PageHeader>
        <h3>Property Team Members</h3>
      </PageHeader>
      <div className={"plp-filter-block" + " " + classes.search_container}>
        <div className={classes.right_section}>
          <div className={`${classes.buttonContainer} ${classes.buttonGap}`}>
            <Button className={classes.btn} onClick={handleCreate}>
              <AddIcon className="icon" />
              <span className="btn_name">New Property Member</span>
            </Button>
            <Button className={classes.btn} onClick={handleCreateBusiness}>
              <AddIcon className="icon" />
              <span className="btn_name">
                {!loading &&
                ((Array.isArray(businessCompany) && businessCompany.length === 0) ||
                  (!Array.isArray(businessCompany) &&
                    Object.keys(businessCompany).length === 0))
                  ? "Create Company Account"
                  : "Edit Company Account"}
              </span>
            </Button>
          </div>
        </div>
      </div>

      <section style={{ marginTop: "40px" }}>
        {!loading && memberList.length === 0 && (
          <SadFaceIconComponent>
            {" "}
            <p>You have not added any member</p>
            <AddButton />
          </SadFaceIconComponent>
        )}
        {loading && (
          <div
            style={{
              width: "100%",
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <CircularProgress />
          </div>
        )}

        {!loading && memberList.length > 0 && (
          <List className={classes.list}>
            {memberList
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((member) => (
                <ListItem key={member._id} className={classes.listItem}>
                  <ListItemText primary={`${member.title},${member.name}`} />
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                      className={classes.button}
                      onClick={() => handleEdit(member._id)}>
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      className={classes.button}
                      onClick={() => handleDelete(member._id)}>
                      Delete
                    </Button>
                  </div>
                </ListItem>
              ))}
          </List>
        )}
      </section>
      <Modal open={toggleModal} onClose={handleModalClose} className={classes.modal}>
        <div
          className={classes.modalContent}
          style={{ width: "50%", height: "70%", padding: "50px 20px", overflow: "auto" }}>
          <h2 style={{ marginBottom: "20px" }}>Edit Member</h2>
          <form onSubmit={handleFormSubmit}>
            <TextField
              className={classes.formField}
              label="Title"
              name="title"
              value={editFormData.title}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              className={classes.formField}
              label="Added By"
              name="added_by"
              value={editFormData.added_by}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              disabled
              style={{ marginBottom: "20px" }}
            />
            <TextField
              className={classes.formField}
              label="Member ID"
              name="member_id"
              value={editFormData.member_id}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            />

            {editFormData.permissions.map((permission, categoryIndex) => (
              <div key={categoryIndex}>
                <FormControl component="fieldset" className={classes.formField}>
                  <FormLabel component="legend">
                    {permission.name.toUpperCase()}
                  </FormLabel>
                  <FormGroup
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "30px"
                    }}>
                    {["view", "edit", "create", "delete"].map(
                      (permissionName, permissionIndex) => (
                        <FormControlLabel
                          key={permissionIndex}
                          control={
                            <Checkbox
                              checked={permission.permissions.includes(permissionName)}
                              onChange={() =>
                                handlePermissionChangeEdit(categoryIndex, permissionName)
                              }
                              name={`${permission.name}.${permissionName}`}
                            />
                          }
                          label={permissionName}
                        />
                      )
                    )}
                  </FormGroup>
                </FormControl>
              </div>
            ))}
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </div>
      </Modal>

      <Modal
        open={toggleCreateModal}
        onClose={handleCreateModalClose}
        className={classes.modal}
        style={{ alignItems: "center", justifyContent: "center" }}>
        <div
          className={classes.modalContent}
          style={{ width: "50%", height: "70%", padding: "50px 20px", overflow: "auto" }}>
          <h2 style={{ marginBottom: "20px" }}>Add Member</h2>
          <form onSubmit={handleFormSubmitCreate}>
            <TextField
              className={classes.formField}
              label="Title(portfolio)"
              name="title"
              value={createFormData.title}
              onChange={handleInputChangeCreate}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            {/* <TextField
              className={classes.formField}
              label="Added By"
              name="added_by"
              value={createFormData.added_by}
              onChange={handleInputChangeCreate}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            /> */}
            <TextField
              className={classes.formField}
              label="Liveizy ID"
              name="member_id"
              value={createFormData.member_id}
              onChange={handleInputChangeCreate}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            />

            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControl component="fieldset" className={classes.formField}>
                <FormLabel component="legend">Permissions</FormLabel>
                {createFormData.permissions.map((permission, index) => (
                  <FormGroup
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "30px"
                    }}>
                    <FormLabel component="legend">
                      {permission.name.toUpperCase()}
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permission.permissions.includes("view")}
                          onChange={() => handlePermissionChange(permission.name, "view")}
                          name={`${permission.name}.view`}
                        />
                      }
                      label="View"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permission.permissions.includes("edit")}
                          onChange={() => handlePermissionChange(permission.name, "edit")}
                          name={`${permission.name}.edit`}
                        />
                      }
                      label="Edit"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permission.permissions.includes("create")}
                          onChange={() =>
                            handlePermissionChange(permission.name, "create")
                          }
                          name={`${permission.name}.create`}
                        />
                      }
                      label="Create"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permission.permissions.includes("delete")}
                          onChange={() =>
                            handlePermissionChange(permission.name, "delete")
                          }
                          name={`${permission.name}.delete`}
                        />
                      }
                      label="Delete"
                    />
                  </FormGroup>
                ))}
              </FormControl>
            </div>

            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        open={toggleBusinessModal}
        onClose={handleBusinessModalClose}
        className={classes.modal}
        style={{ alignItems: "center", justifyContent: "center" }}>
        <div
          className={classes.modalContent}
          style={{ width: "50%", height: "70%", padding: "50px 20px", overflow: "auto" }}>
          <h2 style={{ marginBottom: "20px" }}>Add Member</h2>
          <form onSubmit={handleBusinessCreate}>
            <TextField
              className={classes.formField}
              label="Company Name(Legal Name)"
              name="legal_name"
              value={businessForm.legal_name}
              onChange={handleInputChangeBusiness}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            />

            <TextField
              className={classes.formField}
              label="Address"
              name="address"
              value={businessForm.address}
              onChange={handleInputChangeBusiness}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            />

            <TextField
              className={classes.formField}
              label="Phone"
              name="phone"
              value={businessForm.phone}
              onChange={handleInputChangeBusiness}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            />

            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default PropertyTeam;
