import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PAYMENT_TERMS } from "#/components/apartmentForm/apartmentForm.constants";

import useArrowDropdown from "#/hooks/propertyservice-hooks/useArrowDropdown";
import { format } from "date-fns";
import {
  createChargesForApartment,
  updateChargeForApartment,
  getApartmentCharges,
  deleteChargeForApartment
} from "#/store/actions/charges.actions";

const useApartmentCharge = (apartmentId) => {
  //------------------------------------------------------------------
  //Helper Hooks
  //------------------------------------------------------------------
  const dispatch = useDispatch();

  //Store
  const { loading, deleting } = useSelector((state) => state.charges);

  //Custom hook
  const {
    dropdownOptions,
    selectedOption: paymentTermCharge,
    showDropDown,
    handleToggleDropdown,
    handleSetSelectedOption,
    setSelectedOption
  } = useArrowDropdown(PAYMENT_TERMS);

  //------------------------------------------------------------------
  //States
  //------------------------------------------------------------------
  const [isCreating, setIsCreating] = useState(false);
  const [chargesField, setChargesField] = useState({
    chargeName: "",
    chargeAmount: "",

    // Note this Peter Ihimire
    // paymentDate: format(new Date(), "yyyy-MM-dd")
    paymentDate: ""
  });
  const [errors, setErrors] = useState({
    chargeName: false,
    chargeAmount: false,
    paymentTermCharge: false,

    // Note this Peter Ihimire
    paymentDate: false
  });

  const { chargeName, chargeAmount, paymentDate } = chargesField;

  const [selectedCharge, setSelectedCharge] = useState(null);

  //------------------------------------------------------------------
  //Function declarations
  //------------------------------------------------------------------

  const handleSetChargeToEdit = useCallback((val) => {
    setSelectedCharge(val);
  }, []);

  //Update input fields as user types
  const handleOnChangeInput = useCallback(
    (e) => {
      let { value, name } = e.target;

      //Numeric validation for rent,floor,entrance
      if (name === "chargeAmount") {
        value = value.match(/\d/g) || []; //allow only digits, NOTE: match could return null hence the empty array second option
        value = value.join("");
      }

      setChargesField((prevState) => {
        return {
          ...prevState,
          [name]: value
        };
      });
    },
    [chargesField]
  );

  const handleSetIsCreatingCharge = useCallback(() => {
    setIsCreating(true);
  }, []);

  //Clear the input fields after submitting charge
  const handleClearCharges = () => {
    setChargesField({
      chargeName: "",
      chargeAmount: "",

      // Note this Peter Ihimire
      // paymentDate: format(new Date(), "yyyy-MM-dd")
      paymentDate: ""
    });
    setSelectedOption(PAYMENT_TERMS[0]);
    setIsCreating(false);
    setSelectedCharge(null);
  };

  //Setting errors
  const handleSetErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: true
      };
    });
  }, []);

  //removing errors(NOTE: this was not merged into the handleSetErrors function because of some edge cases)
  const handleRemoveErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: false
      };
    });
  }, []);

  //Submit charge
  const handleSubmitCharge = (e, isDeleting = false) => {
    e.preventDefault();

    //if the forms fields are empty return back to the user
    if (chargeName.trim() === "") {
      return handleSetErrors("chargeName");
    }
    // if (chargeAmount.trim() === "") {
    //   return handleSetErrors("chargeAmount");
    // }
    if (chargeAmount === "") {
      return handleSetErrors("chargeAmount");
    }
    if (paymentTermCharge.mainValue === "select") {
      return handleSetErrors("paymentTermCharge");
    }

    // Note this Peter Ihimire
    if (paymentDate === "") {
      return handleSetErrors("paymentDate");
    }

    // console.log(paymentTermCharge.mainValue, "the term");

    const charges = {
      apartment_id: apartmentId,
      name: chargeName,
      amount: chargeAmount,
      payment_term: paymentTermCharge.mainValue.toLowerCase(),

      // Note this Peter Ihimire
      payment_date: paymentDate
    };

    //If charge details existed then we are trying to update not create
    if (selectedCharge && !isDeleting) {
      dispatch(
        updateChargeForApartment(charges, selectedCharge._id, () => {
          handleClearCharges();
          dispatch(getApartmentCharges(apartmentId));
        })
      );
    } else if (selectedCharge && isDeleting) {
      // console.log(selectedCharge, "the details");
      dispatch(
        deleteChargeForApartment(selectedCharge._id, () => {
          handleClearCharges();
          dispatch(getApartmentCharges(apartmentId));
        })
      );
    } else if (!selectedCharge && !isDeleting) {
      //Clear the field for the user to add another
      dispatch(
        createChargesForApartment(charges, () => {
          handleClearCharges();
          dispatch(getApartmentCharges(apartmentId));
        })
      );
    }
  };

  //------------------------------------------------------------------
  //Remove error message if validation is met
  //------------------------------------------------------------------
  useEffect(() => {
    if (chargeName.trim() !== "") {
      handleRemoveErrors("chargeName");
    }
    // if (chargeAmount.trim() !== "") {
    //   handleRemoveErrors("chargeAmount");
    // }
    if (chargeAmount !== "") {
      handleRemoveErrors("chargeAmount");
    }
    if (paymentTermCharge.mainValue !== "Select") {
      handleRemoveErrors("paymentTermCharge");
    }

    // Note this Peter Ihimire
    if (paymentDate !== "") {
      handleRemoveErrors("paymentDate");
    }
  }, [chargeName, chargeAmount, paymentDate]);

  useEffect(() => {
    if (selectedCharge) {
      const { amount, name, payment_term, payment_date } = selectedCharge;

      //set default charge fields
      setChargesField({
        chargeName: name,
        chargeAmount: amount,
        paymentDate: payment_date
      });

      //set default payment frequency
      const selectedPaymentTerm = PAYMENT_TERMS.filter((item) => {
        return item.mainValue === payment_term;
      });
      setSelectedOption(selectedPaymentTerm[0]);
      setIsCreating(true);
    }
  }, [selectedCharge]);

  return {
    selectedCharge,
    handleOnChangeInput,
    isCreating,
    handleSetIsCreatingCharge,
    handleSetChargeToEdit,
    errors,
    chargeName,
    chargeAmount,
    paymentDate,
    dropdownOptions,
    paymentTermCharge,
    showDropDown,
    handleToggleDropdown,
    handleSetSelectedOption,
    setSelectedOption,
    apartmentId,
    handleSubmitCharge,
    loading,
    handleClearCharges,
    deleting
  };
};

export default useApartmentCharge;
