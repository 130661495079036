import TitleUnderline from "#/components/ui-components/TitleUnderline.component";
import { Button, CircularProgress, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DataTable from "#/components/utils/DataTable";
import axios from "axios";
import PaymentHistoryModal from "./PaymentHistoryModal";
import { formatPrice, renderDate } from "#/shared/utils/general";

const PaymentHistory = () => {
  const history = useHistory();
  const { apartment_id } = useParams();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [invoiceRef, setInvoiceRef] = useState(null);
  const [paymentType, setPaymentType] = useState("all bills"); // Default to "All Bills"

  // const startDate = "";
  // const endDate = "";
  // const amount = "";

  const goBack = () => {
    history.push("/dashboard");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://staging.property-service.liveizy.com/api/bill/get_apartment_bills/${apartment_id}`
        );
        setData(response.data);
        setFilteredData(response.data?.data.filter((el) => el.status === "paid") || []); // Initialize filtered data with all data
      } catch (error) {
        console.error("Error fetching apartment details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [apartment_id]);

  const handlePaymentTypeChange = (event) => {
    const selectedPaymentType = event.target.value;
    setPaymentType(selectedPaymentType);

    if (selectedPaymentType === "monthly bills") {
      setFilteredData(
        data?.data.filter(
          (el) => el.status === "paid" && el.payment_term === "monthly"
        ) || []
      );
    } else {
      setFilteredData(data?.data.filter((el) => el.status === "paid") || []);
    }
  };

  const usersTableColumns = [
    {
      field: "name",
      headerName: "Payment",
      width: 200,
      headerClassName: "bold-header"
    },
    {
      field: "desc",
      headerName: "Payment Description",
      width: 200,
      headerClassName: "bold-header"
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      headerClassName: "bold-header",
      renderCell: (params) => (
        <span style={{ color: "#10609F" }}>{formatPrice(params.value)}</span>
      )
    },
    {
      field: "amount_paid",
      headerName: "Paid",
      width: 200,
      headerClassName: "bold-header",
      renderCell: (params) => (
        <span style={{ color: "#10609F" }}>{formatPrice(params.value)}</span>
      )
    },
    {
      field: "payment_date",
      headerName: "Date Paid",
      width: 200,
      headerClassName: "bold-header",
      renderCell: (params) => renderDate(params.value)
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 200,
      headerClassName: "bold-header",
      renderCell: (params) => {
        const balance = params.row.amount - params.row.amount_paid;
        return <span style={{ color: "#10609F" }}>{formatPrice(balance)}</span>;
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerClassName: "bold-header",
      renderCell: (params) => {
        return (
          <span
            className="tw-capitalize"
            style={
              params.row.status === "unpaid" ? { color: "red" } : { color: "green" }
            }>
            {params.row.status}
          </span>
        );
      }
    },
    {
      field: "view",
      headerName: "",
      width: 200,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setInvoiceRef(params.row.invoiceRef);
            setOpenModal(true);
          }}
          variant="contained"
          disabled={params.row.status === "unpaid"}>
          View Receipt
        </Button>
      )
    }
  ];

  if (isLoading) return <CircularProgress />;
  return (
    <>
      <PaymentHistoryModal
        openModal={openModal}
        closeModal={setOpenModal}
        invoiceRef={invoiceRef}
      />
      <div className="payment-history tw-w-[95%] tw-mx-auto tw-mt-32">
        <TitleUnderline title={"Payment History"} />
        <div className="payment-filter">
          {/* <div>
            <TextField
              fullWidth
              id="startDate"
              name="startDate"
              placeholder="Start Date"
              type="date"
              value={startDate}
              className="filter-input"
            />
          </div>
          <div className="line"></div>
          <div>
            <TextField
              fullWidth
              id="endDate"
              name="endDate"
              placeholder="End Date"
              type="date"
              value={endDate}
            />
          </div>
          <div className="line"></div>
          <div>
            <TextField
              fullWidth
              id="amount"
              name="amount"
              placeholder="Amount"
              type="text"
              value={amount}
              style={{ border: "none" }}
            />
          </div>
          <div className="line"></div> */}
          <div style={{ gap: "20px" }}>
            <InputLabel style={{ marginBottom: "0px" }} id="payment-type-label">
              Payment Type
            </InputLabel>
            <Select
              labelId="payment-type-label"
              id="payment-type-select"
              value={paymentType}
              label="Payment Type"
              onChange={handlePaymentTypeChange}>
              <MenuItem value={"all bills"}>All Bills</MenuItem>
              <MenuItem value={"monthly bills"}>Monthly Bills</MenuItem>
            </Select>
          </div>
          {/* <div className="line"></div>
          <Button
            variant="contained"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem"
            }}>
            <IoIosSearch size={20} />
            Search
          </Button> */}
        </div>

        <div className="tw-w-full tw-my-16">
          <DataTable
            autoHeight
            rows={filteredData}
            columns={usersTableColumns}
            getRowId={(row) => row._id}
          />
        </div>

        <div className="centralize">
          <Button variant="contained" onClick={goBack}>
            Back
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentHistory;
