import React, { useState } from "react";
import ConfirmationPopup from "#/components/popup/confirmation";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "./singleApartmentRibbon.scss";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { returnCommaSepAmount } from "#/lib/validation";
import { deleteApartment } from "#/store/actions/apartment.actions";
import BankAccountModal from "../propertyRibbon/components/BankAccountModal";
import { showNotifications } from "#/store/actions/notification.actions";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { getAllProperties } from "#/store/actions/property.actions";

const options = [
  "Update Apartment",
  "Update Bank Account",
  "Add Charges",
  "Add Inspection Schedule",
  "Delete Apartment"
];

const ITEM_HEIGHT = 48;

const MenuDropdown = ({ setDeleteClick, apartment, handleShowBankModal }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateApartmentClick = () => {
    history.push(`/apartment-edit/${apartment.property}/${apartment._id}`);
  };

  const handleItemSelect = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    if (e.target.textContent === "Update Apartment") return handleUpdateApartmentClick();
    if (e.target.textContent === "Update Bank Account") return handleShowBankModal();
    if (e.target.textContent === "Add Charge") return handleUpdateApartmentClick();
    if (e.target.textContent === "Add Inspection Schedule")
      return handleUpdateApartmentClick();
    if (e.target.textContent === "Delete Apartment") return setDeleteClick(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch"
          }
        }}>
        {options.map((option) => (
          <MenuItem key={option} selected={option === "Pyxis"} onClick={handleItemSelect}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const SingleApartmentRibbon = ({ apartment }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showBankModal, setShowBankModal] = useState(false);
  const [loadingBankSubmit, setLoadingBankSubmit] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    bank: {
      value: null,
      validation: true,
      error: ""
    },
    accountNumber: {
      value: "",
      validation: true,
      error: ""
    }
  });
  const [accountName, setAccountName] = useState("");

  const [deleteClick, setDeleteClick] = useState(false);

  const deleteApartmentAction = () => {
    setDeleteClick(false);
    dispatch(deleteApartment(apartment._id, apartment.property, () => {}));
  };

  const goToApartmentDetail = () => {
    history.push(
      `/property-list/property-apartment/${apartment.property}?apartmentId=${apartment._id}`
    );
  };

  const handleShowBankModal = () => {
    setShowBankModal(!showBankModal);
  };
  const handleBankDetailsSubmit = () => {
    setLoadingBankSubmit(true);
    const payload = {
      bank_accounts: {
        code: bankDetails.bank.value?.code,
        name: bankDetails.bank.value?.name,
        account_number: bankDetails.accountNumber.value,
        account_name: accountName
      }
    };
    propertyServiceApi()
      .patch(`/apartment/add-account/${apartment._id}`, payload)
      .then((data) => {
        setLoadingBankSubmit(false);
        dispatch(showNotifications("Bank Account assigned successfully", "success"));
        dispatch(getAllProperties());
        setShowBankModal(false);
      })
      .catch(({ response }) => {
        setLoadingBankSubmit(false);
        dispatch(showNotifications(response?.data?.error, "error"));
      });
  };

  return (
    <>
      {showBankModal && (
        <BankAccountModal
          attachedBankDetails={apartment.bank_accounts}
          headerName={"Apartment"}
          headerId={`${apartment._id}`}
          showBankModal={showBankModal}
          bankDetails={bankDetails}
          itemId={apartment._id}
          setShowBankModal={setShowBankModal}
          owner={apartment.owner}
          setBankDetails={setBankDetails}
          accountName={accountName}
          setAccountName={setAccountName}
          handleSubmit={handleBankDetailsSubmit}
          handleShowBankModal={handleShowBankModal}
          loadingBankSubmit={loadingBankSubmit}
        />
      )}
      <div id="single-apartment-ribbon" onClick={goToApartmentDetail}>
        <div className="sar-tile-flex-div">
          <h5>Apartment {apartment._id}</h5>
          <p>{apartment.status}</p>
          <p>{apartment.tenant.length ? "Tenant attached" : "No tenant attached"}</p>
          <p>&#8358;{returnCommaSepAmount(apartment.price)}</p>
        </div>
        <MenuDropdown
          handleShowBankModal={handleShowBankModal}
          setDeleteClick={setDeleteClick}
          apartment={apartment}
        />
      </div>
      {deleteClick && (
        <ConfirmationPopup
          title="Delete Property"
          description="Are you sure you want to delete this apartment?"
          handleClose={() => setDeleteClick(false)}
          proceedAction={deleteApartmentAction}
        />
      )}
    </>
  );
};

export default SingleApartmentRibbon;
