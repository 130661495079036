import { propertyServiceApi } from "#/apis/propertyServiceApi";
import CustomBtn from "#/components/buttons/CustomBtn";
import FlexBetween from "#/components/flexboxes/FlexBetween";
import { alertError, alertSuccess } from "#/store/actions/notification.actions";
import { getPropertyById } from "#/store/actions/property.actions";
import {
  getTenantsApartment,
  getTenantsUnderPropertyManager
} from "#/store/actions/tenant.actions";
import { Button, CircularProgress, Dialog, styled } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const RemoveTenantModal = ({ handleRemoveTenantModal, tenantLid, apartmentId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { propertyById } = useSelector((state) => state.property);
  const user = useSelector((state) => state.auth.user);

  const removeTenantFromApartment = async (tenantLid) => {
    if (!tenantLid) return;
    setLoading(true);
    try {
      await propertyServiceApi().delete("/tenant/remove", {
        liveizy_id: tenantLid,
        apartment_id: apartmentId
      });
      setLoading(false);
      dispatch(alertSuccess("Tenant has been removed"));
      dispatch(getPropertyById(propertyById._id, "charges", "tenant"));
      dispatch(getTenantsUnderPropertyManager(user.liveizy_id));
      dispatch(getTenantsApartment(user.liveizy_id));
      handleRemoveTenantModal();
    } catch (error) {
      setLoading(false);
      dispatch(
        alertError(error.response?.data?.error || "Error occured. Try again later")
      );
    }
  };
  return (
    <Dialog onClose={handleRemoveTenantModal} open={true}>
      <Wrapper>
        <p>Are You Sure You Want To Remove This Tenant From This Apartment?</p>

        <FlexBetween style={{ gap: "1rem" }}>
          <Button
            sx={{ fontSize: "0.75rem", padding: "0.5rem 1.6rem" }}
            variant="outlined"
            color="primary"
            onClick={handleRemoveTenantModal}>
            CANCEL
          </Button>
          <CustomBtn
            sx={{ fontSize: "0.75rem", padding: "0.5rem 1.6rem" }}
            disabled={loading}
            onClick={() => removeTenantFromApartment(tenantLid)}>
            {loading ? <CircularProgress size={16} color="inherit" /> : "CONTINUE"}
          </CustomBtn>
        </FlexBetween>
      </Wrapper>
    </Dialog>
  );
};

export default RemoveTenantModal;

const Wrapper = styled("div")`
  padding: 3rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  p {
    max-width: 36ch;
    width: 100%;
    color: #052137;
    text-align: center;
    font-weight: 600;
    font-size: 1.3rem;
  }
`;
