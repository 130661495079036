// import { toCalenderDate } from "#/components/utils/util";
import { thousandSeparator } from "#/shared/utils";
import { toCurrency } from "#/components/utils/util";
import { styled } from "@mui/material";
import React from "react";

const RentalPaymentTable = ({ billsList, partRent, setPartRent, handleSelectBill }) => {
  const handleInputChange = (event, setValue) => {
    if (!event.target.value) return setValue(0);
    setValue(thousandSeparator(event.target.value));
  };
  return (
    <PaymentTable>
      <thead>
        <tr>
          <td data-cell="checkbox"></td>
          <td data-cell="item">Item</td>
          <td data-cell="description">Description</td>
          <td data-cell="amount">Amount (N)</td>
          <td data-cell="part-payment">Part Payment (N)</td>
        </tr>
      </thead>
      <tbody>
        {billsList?.map((bill) => {
          if (bill?.name === "House Rent") {
            return (
              <tr key={bill?._id}>
                <td data-cell="checkbox">
                  <input
                    style={{ accentColor: "#ff0000" }}
                    type="checkbox"
                    defaultChecked={bill?.selected || false}
                    onChange={() => handleSelectBill(bill)}
                  />
                </td>
                <td data-cell="item">{bill?.name}</td>
                <td data-cell="description">{bill?.desc}</td>
                {/* <td data-cell="description">{toCalenderDate(bill.desc)}</td> */}
                <td data-cell="amount">{toCurrency(bill?.amount_due)}</td>
                <td data-cell="part-payment">
                  <PaymentInput
                    type="text"
                    value={partRent}
                    onChange={(event) => handleInputChange(event, setPartRent)}
                  />
                </td>
              </tr>
            );
          }
          return (
            <tr key={bill?._id}>
              <td data-cell="checkbox">
                <input
                  style={{ accentColor: "#ff0000" }}
                  type="checkbox"
                  defaultChecked={bill?.selected || false}
                  onChange={() => handleSelectBill(bill)}
                />
              </td>
              <td data-cell="item">{bill?.name}</td>
              <td data-cell="description">{bill?.desc}</td>
              <td data-cell="amount">{toCurrency(bill?.amount_due)}</td>
              <td data-cell="part-payment">
                <DisabledInput>0.00</DisabledInput>
              </td>
            </tr>
          );
        })}
      </tbody>
    </PaymentTable>
  );
};

export default RentalPaymentTable;

const PaymentTable = styled("table")`
  width: 100%;
  font-size: 0.9rem;
  thead tr {
    color: #052137cc;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid black;
    font-size: 0.775rem;
    td {
      padding: 0.7rem 0;
    }
  }
  td {
    text-align: left;
  }

  tbody {
    tr td {
      padding: 0.7rem 0;
    }

    td:nth-of-type(1) {
      max-width: 1rem;
    }

    td:nth-of-type(2) {
      font-size: 1.1rem;
      color: #052137;
      font-weight: 600;
    }

    td:nth-of-type(3) {
      color: #052137;
      font-weight: 600;
    }

    td:nth-of-type(4) {
      color: #10609f;
      font-weight: 900;
    }
  }
`;

const DisabledInput = styled("div")`
  border-radius: 6px;
  background: #cacaca;
  max-width: 200px;
  width: 100%;
  color: #00000040;
  font-weight: 600;
  height: 35px;
  padding: 5px 10px;
`;

const PaymentInput = styled("input")`
  border-radius: 6px;
  border: 3px solid #10609f;
  max-width: 200px;
  width: 100%;
  color: #10609f;
  font-weight: 600;
  height: 35px;
  padding: 5px 10px;

  :focus {
    outline: none;
    border-color: #1b486d;
  }
`;
// <PaymentTable>
//   <thead>
//     <tr>
//       <td data-cell="checkbox"></td>
//       <td data-cell="item">Item</td>
//       <td data-cell="description">Description</td>
//       <td data-cell="amount">Amount (N)</td>
//       <td data-cell="part-payment">Part Payment (N)</td>
//     </tr>
//   </thead>
//   <tbody>
//     {billsList?.map((bill) => {
//       if (bill.description === RENTDESCRIPTION) {
//         return (
//           <tr key={bill._id}>
//             <td data-cell="checkbox">
//               <input
//                 style={{ accentColor: "#ff0000" }}
//                 type="checkbox"
//                 defaultChecked={bill.selected || false}
//                 onChange={() => handleSelectBill(bill)}
//               />
//             </td>
//             <td data-cell="item">RENT</td>
//             <td data-cell="description">{toCalenderDate(bill.start_date)}</td>
//             <td data-cell="amount">{thousandSeparator(bill.amount_pending)}</td>
//             <td data-cell="part-payment">
//               <PaymentInput
//                 type="text"
//                 value={partRent}
//                 onChange={(event) => handleInputChange(event, setPartRent)}
//               />
//             </td>
//           </tr>
//         );
//       }
//       return (
//         <tr key={bill._id}>
//           <td data-cell="checkbox">
//             <input
//               style={{ accentColor: "#ff0000" }}
//               type="checkbox"
//               defaultChecked={bill.selected || false}
//               onChange={() => handleSelectBill(bill)}
//             />
//           </td>
//           <td data-cell="item">{bill.description.slice(12)}</td>
//           <td data-cell="description">{toCalenderDate(bill.due_date)}</td>
//           <td data-cell="amount">{thousandSeparator(bill.amount_due)}</td>
//           <td data-cell="part-payment">
//             <DisabledInput>0.00</DisabledInput>
//           </td>
//         </tr>
//       );
//     })}
//   </tbody>
// </PaymentTable>
