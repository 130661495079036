import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Menu,
  MenuItem,
  IconButton,
  Modal,
  TextField,
  Select,
  InputLabel,
  FormControl,
  CircularProgress
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "./singlePropertyRibbon.scss";
import { useHistory } from "react-router";
import ConfirmationPopup from "#/components/popup/confirmation";
import { useDispatch } from "react-redux";
import {
  deleteProperty,
  getAllProperties,
  populateCurrentPropertyInfo
} from "#/store/actions/property.actions";
import SingleApartmentRibbon from "../apartmentRibbon/SingleApartmentRibbon";
import { Button } from "@mui/material";
import BankAccountModal from "./components/BankAccountModal";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { showNotifications } from "#/store/actions/notification.actions";

const options = [
  "Add Apartment",
  "Add to LiReM",
  "Assign Bank Account",
  "Assign Property Manager",
  "Add to LiPMaN",
  "Update Property",
  "Delete Property"
];

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 20
  },
  summaryRoot: {
    //padding: '0 16px 0 0',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  list: {
    backgroundColor: "#fafafa",
    padding: "10px",
    borderRadius: "5px"
  },
  listItem: {
    marginBottom: "10px", // Add margin bottom to create space between list items
    padding: "10px", // Add padding to the list item
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    backgroundColor: "#4786ff", // Darker background color for list items
    borderRadius: "5px" // Add border radius to list items
  },
  buttonContainer: {
    display: "flex"
  },
  button: {
    marginLeft: "10px" // Add margin between buttons
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px"
  },
  formField: {
    marginBottom: theme.spacing(2)
  }
}));

const MenuDropdown = ({ setDeleteClick, property, handleShowBankModal }) => {
  const accordionClasses = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCreatePropertyClick = () => {
    dispatch(
      populateCurrentPropertyInfo(property, () => {
        history.push({ pathname: "/create/apartment" });
      })
    );
  };
  const [toggleModal, setToggleModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);
  const [selectedTeamMember, selectTeamMember] = useState({});
  const [selectedPermission, selectPermission] = useState({});
  const [addMemberData, setAddMemberData] = useState({
    property_manager_lid: "",
    landLord_id: "",
    permission_type: "",
    permissions: []
  });

  const handleModalOpen = () => {
    setAddMemberData({
      property_manager_lid: "",
      landLord_id: property.owner,
      permission_type: "",
      permissions: []
    });
    setToggleModal(true);
  };

  const handleModalClose = () => {
    setToggleModal(false);
  };

  const handleChangeSelect = (member) => {
    selectTeamMember({
      title: member.title,
      id: member.member_id,
      permissions: member.permissions
    });

    //Updated member data state to factor new state
    const newData = {
      property_manager_lid: member.member_id, // Assuming _id is the correct property
      landLord_id: property.owner,
      permission_type: member.permission_type,
      permissions: member.permissions
    };
    setAddMemberData(newData);
    //   console.log(selectedTeamMember);
  };
  const handlePermissionChange = (e) => {
    const selectedValue = e.target.value;
    selectPermission(selectedValue);

    //Updated member data state to factor new state
    setAddMemberData({
      property_manager_lid: selectedTeamMember.id, // Assuming _id is the correct property
      landLord_id: property.owner,
      permission_type: selectedValue,
      permissions: selectedTeamMember.permissions
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddMemberData({
      ...addMemberData,
      [name]: value
    });
  };

  const handleFormSubmit = async (event) => {
    //Form submission logic
    event.preventDefault();
    try {
      // Make sure all fields are filled
      // if (!addMemberData.property_manager_lid || !addMemberData.landLord_id) {
      //   dispatch(showNotifications("Please fill in all fields", "error"));
      //   return;
      // }

      // Construct the data object
      const formData = {
        property_manager_lid: addMemberData.property_manager_lid,
        landLord_id: property.owner,
        permission_type: addMemberData.permission_type,
        permissions: addMemberData.permissions || selectedTeamMember.permissions
      };

      // Make the POST request
      const response = await propertyServiceApi().patch(
        `/property/add-pm/${property._id}`,
        formData
      );
      dispatch(showNotifications("Member assigned successfully", "success"));
      handleModalClose();
    } catch (error) {
      // Handle errors
      console.error("Error submitting form:", error);
      const errorMessage =
        error.response?.data?.error || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  useEffect(() => {
    fetchMembersFromAPI();
  }, []);

  const fetchMembersFromAPI = async () => {
    try {
      const response = await propertyServiceApi().get(`/account-member`);
      setTeamData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage =
        error.response?.data?.error || "An error occurred, try again later.";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const handleItemSelect = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    if (e.target.textContent === "Add Apartment") return handleCreatePropertyClick();
    if (e.target.textContent === "Update Property")
      return history.push(`/property-edit/${property._id}`);
    if (e.target.textContent === "Assign Bank Account") return handleShowBankModal();
    if (e.target.textContent === "Assign Property Manager") return handleModalOpen();
    if (e.target.textContent === "Delete Property") return setDeleteClick(true);
    if (e.target.textContent === "Add to LiReM")
      return history.push("/property/plan/lirem");
    if (e.target.textContent === "Add to LiPMaN")
      return history.push("/property/plan/lipman");
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch"
          }
        }}>
        {options.map((option) => (
          <MenuItem key={option} selected={option === "Pyxis"} onClick={handleItemSelect}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Modal
        open={toggleModal}
        onClose={handleModalClose}
        className={accordionClasses.modal}>
        <div className={accordionClasses.modalContent}>
          <h2 style={{ marginBottom: "20px" }}>Add Member To Property</h2>
          <form onSubmit={handleFormSubmit}>
            <FormControl variant="outlined" fullWidth style={{ marginBottom: "10px" }}>
              <InputLabel style={{ color: "#106f9f" }}>Select Account Members</InputLabel>
              <Select
                style={{ marginTop: "20px" }}
                value={selectedTeamMember.id}
                onChange={(e) => handleChangeSelect(e.target.value)}>
                {teamData.map((option) => (
                  <MenuItem key={option._id} value={option}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl variant="outlined" fullWidth style={{ marginBottom: "10px" }}>
              <InputLabel style={{ color: "#106f9f" }}>Select Role</InputLabel>
              <Select
                style={{ marginTop: "20px" }}
                value={selectedPermission}
                onChange={()=>handlePermissionChange}>
                <MenuItem key="1" value="property_manager">
                  Manager
                </MenuItem>
                <MenuItem key="2" value="landlord">
                  Landlord
                </MenuItem>
              </Select>
            </FormControl> */}
            {/* <TextField
              className={accordionClasses.formField}
              label="Property Manager LID"
              name="property_manager_lid"
              value={addMemberData.property_manager_lid}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            /> */}
            {/* <TextField
              className={accordionClasses.formField}
              label="Landlord LID"
              name="landLord_id"
              value={addMemberData.landLord_id}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            /> */}
            {/* <TextField
              className={accordionClasses.formField}
              label="Permission Type"
              name="permission_type"
              value={addMemberData.permission_type}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
            /> */}
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

const SinglePropertyRibbon = ({ property }) => {
  const accordionClasses = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [deleteClick, setDeleteClick] = useState(false);
  const [showBankModal, setShowBankModal] = useState(false);
  const [loadingBankSubmit, setLoadingBankSubmit] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    bank: {
      value: null,
      validation: true,
      error: ""
    },
    accountNumber: {
      value: "",
      validation: true,
      error: ""
    }
  });

  const [accountName, setAccountName] = useState("");

  const goToPropertyDetail = () => {
    history.push(`/property-list/property-apartment/${property._id}`);
  };

  const deletePropertyAction = () => {
    setDeleteClick(false);
    dispatch(deleteProperty(property._id, () => {}));
  };

  const handleShowBankModal = () => {
    setShowBankModal(!showBankModal);
  };
  const handleBankDetailsSubmit = () => {
    setLoadingBankSubmit(true);
    const payload = {
      bank_accounts: {
        code: bankDetails.bank.value?.code,
        name: bankDetails.bank.value?.name,
        account_number: bankDetails.accountNumber.value,
        account_name: accountName
      }
    };
    propertyServiceApi()
      .patch(`/property/add-account/${property._id}`, payload)
      .then((data) => {
        setLoadingBankSubmit(false);
        dispatch(showNotifications("Bank Account assigned successfully", "success"));
        dispatch(getAllProperties());
        setShowBankModal(false);
      })
      .catch(({ response }) => {
        setLoadingBankSubmit(false);
        dispatch(showNotifications(response?.data?.error, "error"));
      });
  };

  return (
    <>
      <div id="single-property-ribbon" className={accordionClasses.root}>
        {showBankModal && (
          <BankAccountModal
            attachedBankDetails={property.bank_accounts}
            headerName={"Property"}
            headerId={`${property.title} (${property._id})`}
            itemId={property._id}
            setShowBankModal={setShowBankModal}
            owner={property.owner}
            showBankModal={showBankModal}
            bankDetails={bankDetails}
            setBankDetails={setBankDetails}
            accountName={accountName}
            setAccountName={setAccountName}
            handleSubmit={handleBankDetailsSubmit}
            handleShowBankModal={handleShowBankModal}
            loadingBankSubmit={loadingBankSubmit}
          />
        )}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={accordionClasses.summaryRoot}>
            <div className="spr-summary-flex-div">
              <div>
                <p className="spr-summary-title" onClick={goToPropertyDetail}>
                  {property.title} ({property._id})
                </p>
                <span>{`${property.house_number} ${property.street} ${
                  property.other_address || ""
                }`}</span>
              </div>
              <p className="spr-sfd-end-par">{property.apartment.length} apartment(s)</p>
            </div>
            <MenuDropdown
              handleShowBankModal={handleShowBankModal}
              setDeleteClick={setDeleteClick}
              property={property}
            />
          </AccordionSummary>
          <AccordionDetails>
            <div className="spr-accordion-details">
              {!property.apartment.length && <h6>No apartment added yet</h6>}
              {property.apartment.map((apartment, idx) => {
                return <SingleApartmentRibbon key={idx} apartment={apartment} />;
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      {deleteClick && (
        <ConfirmationPopup
          title="Delete Property"
          description="Are you sure you want to delete this property?"
          handleClose={() => setDeleteClick(false)}
          proceedAction={deletePropertyAction}
        />
      )}
    </>
  );
};

export default SinglePropertyRibbon;
